import LayoutPrimary from 'layouts/LayoutPrimary';
import BarSearch from 'pages/components/BarSearch';
import TablePeopleScroll from 'pages/components/TablePeopleScroll';
import { getPublicUsers } from 'services/userServices';
import { addPublicUsers } from 'states/People/peopleSlice';

function PagePeople() {
  return (
    <LayoutPrimary>
      <>
        <BarSearch placeholder="Search name" />
        <TablePeopleScroll fetchData={getPublicUsers} dispatchAction={addPublicUsers} />
      </>
    </LayoutPrimary>
  );
}

export default PagePeople;
