import { Message } from 'states/Message/Message';
import {
  Image,
  Media,
  Video,
} from 'states/MyMedias/MyMedias';

export const sortMediasByDate = (medias: Media[]) => {
  if (medias.length < 2) return medias;
  return [...medias].sort((a: Media, b: Media) => {
    return b.dateCreated && a.dateCreated
      ? new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
      : 0;
  });
};

export const sortImagesByNbLikes = (images: Image[]) => {
  if (images.length < 2) return images;
  return [...images].sort((a, b) => b.nbLikes - a.nbLikes);
};

export const sortVideosByNbViews = (videos: Video[]) => {
  if (videos.length < 2) return videos;
  return [...videos].sort((a, b) => b.nbViews - a.nbViews);
};

export const sortMessagesByNewDate = (messages: Message[]) => {
  if (messages.length < 2) return messages;

  return [...messages].sort((a: Message, b: Message) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
};

export const sortMessagesByOldDate = (messages: Message[]) => {
  if (messages === undefined || messages.length < 2) return messages;
  return [...messages].sort((a: Message, b: Message) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });
};
