import { Link } from 'react-router-dom';
import { numberFormatter } from 'utils/formatters';

interface Props {
  linkTo: string;
  textColor: string;
  children: any;
  badge: number;
}

function BarItem({ linkTo, textColor, children, badge }: Props) {
  return (
    <Link to={linkTo} className={`relative mx-auto ${textColor}`}>
      {children}
      <span className="absolute -top-1 -right-1 w-1 h-1 font-semibold">
        {numberFormatter.format(badge)}
      </span>
    </Link>
  );
}

export default BarItem;
