import { IconPlay } from 'global/lib/icons';
import { Video } from 'states/MyMedias/MyMedias';

import CardMediaSmall from '../';
import CardVideoHidden from '../../CardMediaHidden/CardVideoHidden';

function CardVideoSmall({ video }: { video: Video }) {
  return (
    <CardVideoHidden video={video}>
      <CardMediaSmall media={video}>
        <>
          <IconPlay fontSize="small" />
          <span>{video.nbViews}</span>
        </>
      </CardMediaSmall>
    </CardVideoHidden>
  );
}

export default CardVideoSmall;
