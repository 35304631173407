import { TextColor } from 'global/enums/colors';

import {
  IconBookmarkSolid,
  IconFriendsSolid,
  IconHomeSolid,
  IconInboxSolid,
  IconLoveSolid,
  IconPersonSearchSolid,
  IconPhotoSolid,
  IconProfileSolid,
  IconScrollToTop,
  IconShareSolid,
  IconVideoSolid,
} from './icons';

export const IconHomeSolidPrimary = () => <IconHomeSolid className={TextColor.PRIMARY} />;
export const IconFollowingSolidPrimary = () => <IconFriendsSolid className={TextColor.PRIMARY} />;
export const IconInboxSolidPrimary = () => <IconInboxSolid className={TextColor.PRIMARY} />;
export const IconProfileSolidPrimary = () => <IconProfileSolid className={TextColor.PRIMARY} />;
export const IconPersonSearchSolidPrimary = () => (
  <IconPersonSearchSolid className={TextColor.PRIMARY} />
);

export const IconVideoSolidPrimary = () => <IconVideoSolid className={TextColor.PRIMARY} />;
export const IconPhotoSolidPrimary = () => <IconPhotoSolid className={TextColor.PRIMARY} />;
export const IconShareSolidPrimary = () => <IconShareSolid className={TextColor.PRIMARY} />;
export const IconLoveSolidPrimary = () => <IconLoveSolid className={TextColor.PRIMARY} />;
export const IconBookmarksSolidPrimary = () => <IconBookmarkSolid className={TextColor.PRIMARY} />;

export const IconScrollToTopPrimary = () => <IconScrollToTop className={TextColor.PRIMARY} />;
