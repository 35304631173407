import { useMemo } from 'react';

import { AvatarMedium } from 'global/components/Avatar';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectFollowingsUsers } from 'states/People/peopleSelectors';
import { UserWithMessages } from 'states/User/User';
import { cutString } from 'utils/formatters';

import ButtonDelete from './ButtonDelete';

function RowInboxSummary({ person }: { person: UserWithMessages }) {
  const followings = useSelector(selectFollowingsUsers);
  const isFollowing = useMemo(() => followings.includes(person), []);

  return person.lastMessage ? (
    <div className="grid grid-cols-12 gap-2 items-center">
      <div className="col-span-2 flex justify-end">
        <AvatarMedium src={person.avatar} />
      </div>
      <Link to={person.username} className="col-span-8 flex flex-col">
        <div>{cutString(`${person.firstName} ${person.lastName}`, 15)}</div>
        <div>{cutString(person.lastMessage.content, 20)}</div>
      </Link>

      <div className="col-span-2">
        <ButtonDelete />
      </div>
    </div>
  ) : null;
}

export default RowInboxSummary;
