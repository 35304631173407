import { useMemo } from 'react';

import { BorderColor } from 'global/enums/colors';
import { IconCamera } from 'global/lib/icons';
import {
  IconFollowingsDefault,
  IconHomeDefault,
  IconInboxDefault,
  IconProfileDefault,
} from 'global/lib/IconsDefault';
import {
  IconFollowingSolidPrimary,
  IconHomeSolidPrimary,
  IconInboxSolidPrimary,
  IconProfileSolidPrimary,
} from 'global/lib/IconsPrimary';
import { useSelector } from 'react-redux';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import { paths } from 'routes/paths';
import { selectUser } from 'states/User/userSelectors';
import {
  isHomePage,
  isInboxPage,
  isMyFolowingMediasPage,
  isProfilePage,
} from 'utils/pathCheck';

export default function Footer() {
  const user = useSelector(selectUser);
  const location = useLocation();

  const icons = useMemo(() => {
    return {
      home: isHomePage(location.pathname, user.username) ? (
        <IconHomeSolidPrimary />
      ) : (
        <IconHomeDefault />
      ),
      followingsMedias: isMyFolowingMediasPage(location.pathname) ? (
        <IconFollowingSolidPrimary />
      ) : (
        <IconFollowingsDefault />
      ),
      inbox: isInboxPage(location.pathname) ? <IconInboxSolidPrimary /> : <IconInboxDefault />,
      profile: isProfilePage(location.pathname) ? (
        <IconProfileSolidPrimary />
      ) : (
        <IconProfileDefault />
      ),
    };
  }, [location]);

  return (
    <div className="sticky bottom-0 left-0 z-10 py-2 px-3 text-xs">
      <div className="flex justify-between items-center">
        <Link
          to={user === undefined ? paths.index : `/${user.username}${paths.home}`}
          className="flex flex-col items-center"
        >
          {icons.home}
        </Link>
        <Link
          to={`/${user.username}${paths.followings.medias}`}
          className="flex flex-col items-center"
        >
          {icons.followingsMedias}
        </Link>
        <div className="flex flex-col items-center">
          <button className={`bg-black text-white p-2 rounded-full border ${BorderColor.PRIMARY}`}>
            <IconCamera />
          </button>
        </div>
        <Link to={`/${user.username}${paths.inbox}`} className="flex flex-col items-center">
          {icons.inbox}
        </Link>
        <Link to={`/${user.username}${paths.profile.index}`} className="flex flex-col items-center">
          {icons.profile}
        </Link>
      </div>
    </div>
  );
}
