import { Media } from 'states/MyMedias/MyMedias';
import { SimpleUser } from 'states/User/User';

export const mySharedMedias: Media[] = [
  {
    link: 'https://picsum.photos/200/300?random=726',
    nbLikes: 597,
    owner: {
      uuid: 'dd4d2fca-ca90-4c1d-b5c8-ff33ce1c94c2',
      firstName: 'Sahar',
      lastName: 'Sundberg',
      username: 'lazywolf984',
      avatar: 'https://randomuser.me/api/portraits/women/75.jpg',
    } as SimpleUser,
    dateCreated: '2013-05-11T10:27:40.453Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=340',
    nbLikes: 817,
    owner: {
      uuid: 'a458b2c8-ea7e-4730-80c1-76377e6d9a0c',
      firstName: 'Maria',
      lastName: 'Hauptmann',
      username: 'bigladybug451',
      avatar: 'https://randomuser.me/api/portraits/women/45.jpg',
    } as SimpleUser,
    dateCreated: '2014-04-11T08:06:06.123Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=151',
    nbLikes: 432,
    owner: {
      uuid: 'd34af471-6cec-400b-89a2-4fd01fb039ef',
      firstName: 'Starodum',
      lastName: 'Govdya',
      username: 'purplecat378',
      avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
    } as SimpleUser,
    dateCreated: '2014-10-25T15:08:38.381Z',
  },
  {
    link: 'https://media.tenor.com/6tlB3xGf1AoAAAAC/cat-white.gif',
    nbViews: 181,
    owner: {
      uuid: '60c490f7-1b64-4935-b0c9-dae5ca1db6ae',
      firstName: 'Gregorio',
      lastName: 'Blanco',
      username: 'smalllion853',
      avatar: 'https://randomuser.me/api/portraits/men/33.jpg',
    } as SimpleUser,
    dateCreated: '2015-07-14T14:19:31.807Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=615',
    nbLikes: 741,
    owner: {
      uuid: '1478ab67-c296-4db1-a155-79fcc67a63fa',
      firstName: 'Andreas',
      lastName: 'Johansen',
      username: 'goldengoose522',
      avatar: 'https://randomuser.me/api/portraits/men/78.jpg',
    } as SimpleUser,
    dateCreated: '2012-01-11T10:58:15.411Z',
  },
  {
    link: 'https://media.tenor.com/AfbRu9zthJsAAAAC/a-really-random-thing-tracy-kiss.gif',
    nbViews: 307,
    owner: {
      uuid: '52a7aacb-9e49-410c-a6e7-162b52c21266',
      firstName: 'Krisha',
      lastName: 'Bharanya',
      username: 'lazybird454',
      avatar: 'https://randomuser.me/api/portraits/women/30.jpg',
    } as SimpleUser,
    dateCreated: '2012-04-21T06:26:43.064Z',
  },
  {
    link: 'https://media.tenor.com/xpfRLZxjvVwAAAAC/random.gif',
    nbViews: 704,
    owner: {
      uuid: '6e97718a-8316-41fe-bf23-0dc47c1bace4',
      firstName: 'رهام',
      lastName: 'کریمی',
      username: 'blackelephant145',
      avatar: 'https://randomuser.me/api/portraits/men/25.jpg',
    } as SimpleUser,
    dateCreated: '2020-12-09T19:24:42.997Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=560',
    nbLikes: 13,
    owner: {
      uuid: 'af6ee453-494d-4fa7-9932-3e9b8825ae69',
      firstName: 'Matthew',
      lastName: 'West',
      username: 'bluecat310',
      avatar: 'https://randomuser.me/api/portraits/men/69.jpg',
    } as SimpleUser,
    dateCreated: '2017-03-20T08:22:38.074Z',
  },
  {
    link: 'https://media.tenor.com/AC62zg5nPH4AAAAC/random.gif',
    nbViews: 831,
    owner: {
      uuid: 'ec764ed7-eee4-457b-9aee-38ee9629241e',
      firstName: 'Timm',
      lastName: 'Schmieder',
      username: 'tinytiger298',
      avatar: 'https://randomuser.me/api/portraits/men/89.jpg',
    } as SimpleUser,
    dateCreated: '2020-08-05T12:04:27.093Z',
  },
  {
    link: 'https://media.tenor.com/qj3vwUKiCvYAAAAC/random-funny.gif',
    nbViews: 624,
    owner: {
      uuid: '3742e9a8-c064-48ab-81f0-11b1b878d608',
      firstName: 'Dimitrije',
      lastName: 'Nenadović',
      username: 'yellowbird189',
      avatar: 'https://randomuser.me/api/portraits/men/7.jpg',
    } as SimpleUser,
    dateCreated: '2016-02-02T19:01:34.898Z',
  },
  {
    link: 'https://media.tenor.com/_l2HP4a_chUAAAAC/arashi-rocket-league.gif',
    nbViews: 815,
    owner: {
      uuid: '80f01e24-9efd-4b88-8d7d-fcf024baab34',
      firstName: 'Leah',
      lastName: 'Anderson',
      username: 'ticklishzebra788',
      avatar: 'https://randomuser.me/api/portraits/women/14.jpg',
    } as SimpleUser,
    dateCreated: '2015-09-12T23:20:29.768Z',
  },
  {
    link: 'https://media.tenor.com/TFKrzUaThWUAAAAC/pizza-sexy.gif',
    nbViews: 762,
    owner: {
      uuid: 'fcaf1787-23bd-49ff-b648-ba1342eff15e',
      firstName: 'Walníria',
      lastName: 'Porto',
      username: 'lazyostrich694',
      avatar: 'https://randomuser.me/api/portraits/women/24.jpg',
    } as SimpleUser,
    dateCreated: '2013-11-02T11:51:20.852Z',
  },
  {
    link: 'https://media.tenor.com/13t9cpC8Ou0AAAAC/random.gif',
    nbViews: 294,
    owner: {
      uuid: '6387deee-446b-4841-8d97-08866ab8c580',
      firstName: 'Eugenia',
      lastName: 'Ceja',
      username: 'lazybear555',
      avatar: 'https://randomuser.me/api/portraits/women/42.jpg',
    } as SimpleUser,
    dateCreated: '2021-02-14T11:12:50.832Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=644',
    nbLikes: 154,
    owner: {
      uuid: '259f103e-0dd3-4c2d-a8aa-17a067b97f46',
      firstName: 'Cathrine',
      lastName: 'Hovdal',
      username: 'redbutterfly523',
      avatar: 'https://randomuser.me/api/portraits/women/36.jpg',
    } as SimpleUser,
    dateCreated: '2019-12-07T20:22:40.075Z',
  },
  {
    link: 'https://media.tenor.com/s4do6pySWMQAAAAC/random.gif',
    nbViews: 59,
    owner: {
      uuid: 'ddc4349d-7310-4aa0-a11f-eba40a46ed63',
      firstName: 'Salvador',
      lastName: 'Schmidt',
      username: 'bigzebra896',
      avatar: 'https://randomuser.me/api/portraits/men/29.jpg',
    } as SimpleUser,
    dateCreated: '2019-07-22T19:38:35.230Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=858',
    nbLikes: 273,
    owner: {
      uuid: '695cd8a6-6221-4300-9668-101740025f5e',
      firstName: 'Gregory',
      lastName: 'Miles',
      username: 'heavylion168',
      avatar: 'https://randomuser.me/api/portraits/men/49.jpg',
    } as SimpleUser,
    dateCreated: '2020-05-05T23:46:03.017Z',
  },
  {
    link: 'https://media.tenor.com/0C3wYUtAb78AAAAC/david-kubrt-kubrt.gif',
    nbViews: 40,
    owner: {
      uuid: '758c178c-c07f-40e6-866b-029dc7f7ae17',
      firstName: 'Florence',
      lastName: 'Gagné',
      username: 'sadbear246',
      avatar: 'https://randomuser.me/api/portraits/women/76.jpg',
    } as SimpleUser,
    dateCreated: '2012-03-13T08:10:38.389Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=119',
    nbLikes: 357,
    owner: {
      uuid: 'cc44522c-039c-4d68-8baf-30b4a0816a70',
      firstName: 'Rosalyn',
      lastName: 'Robertson',
      username: 'yellowduck140',
      avatar: 'https://randomuser.me/api/portraits/women/64.jpg',
    } as SimpleUser,
    dateCreated: '2017-07-06T18:19:23.418Z',
  },
  {
    link: 'https://media.tenor.com/H_Htc8iPURMAAAAC/aot.gif',
    nbViews: 605,
    owner: {
      uuid: 'd2ae7c1c-8401-4c26-8a6f-284720061ab3',
      firstName: 'Samuel',
      lastName: 'Chan',
      username: 'happywolf247',
      avatar: 'https://randomuser.me/api/portraits/men/48.jpg',
    } as SimpleUser,
    dateCreated: '2020-07-09T10:14:44.606Z',
  },
  {
    link: 'https://media.tenor.com/mLuTCIhpCVkAAAAC/pinkant-venomgoinglimited.gif',
    nbViews: 456,
    owner: {
      uuid: 'b40b1f79-ca9c-4fc4-9f43-4f22948d26f4',
      firstName: 'Ramazan',
      lastName: 'Heimans',
      username: 'crazyduck333',
      avatar: 'https://randomuser.me/api/portraits/men/16.jpg',
    } as SimpleUser,
    dateCreated: '2021-03-15T17:38:37.558Z',
  },
  {
    link: 'https://media.tenor.com/VQezvBxsbuIAAAAC/randomduchateau-random.gif',
    nbViews: 727,
    owner: {
      uuid: '07782d3c-1302-470d-a3e6-7bd4930dfeb2',
      firstName: 'Addison',
      lastName: 'Kowalski',
      username: 'happykoala720',
      avatar: 'https://randomuser.me/api/portraits/women/60.jpg',
    } as SimpleUser,
    dateCreated: '2012-08-17T06:15:13.066Z',
  },
  {
    link: 'https://media.tenor.com/jqS9Bb71T6YAAAAC/random.gif',
    nbViews: 112,
    owner: {
      uuid: 'dd0191aa-310e-4bb6-ad56-326ee27ccd29',
      firstName: 'Silas',
      lastName: 'Rasmussen',
      username: 'greenkoala293',
      avatar: 'https://randomuser.me/api/portraits/men/89.jpg',
    } as SimpleUser,
    dateCreated: '2022-07-24T02:18:18.739Z',
  },
  {
    link: 'https://media.tenor.com/7Yb2DzUc9-8AAAAC/random-weird.gif',
    nbViews: 766,
    owner: {
      uuid: '040c1136-4208-42a0-9c42-15e2439d0355',
      firstName: 'Aishwarya',
      lastName: 'Dsouza',
      username: 'blackbutterfly383',
      avatar: 'https://randomuser.me/api/portraits/women/27.jpg',
    } as SimpleUser,
    dateCreated: '2014-07-27T23:36:55.273Z',
  },
  {
    link: 'https://media.tenor.com/fFntTHJYFPMAAAAC/random.gif',
    nbViews: 159,
    owner: {
      uuid: 'e879988f-edd1-4bda-83e0-f74eaa0e23e0',
      firstName: 'Rose',
      lastName: 'Lavoie',
      username: 'angryzebra494',
      avatar: 'https://randomuser.me/api/portraits/women/65.jpg',
    } as SimpleUser,
    dateCreated: '2019-08-27T00:55:25.606Z',
  },
  {
    link: 'https://media.tenor.com/eXmRdwe45asAAAAC/random-snl.gif',
    nbViews: 805,
    owner: {
      uuid: '29e970f7-4a24-4c72-8b84-847dca6f9abc',
      firstName: 'Claude',
      lastName: 'Mitchelle',
      username: 'orangeelephant585',
      avatar: 'https://randomuser.me/api/portraits/men/86.jpg',
    } as SimpleUser,
    dateCreated: '2017-12-18T19:33:33.307Z',
  },
  {
    link: 'https://media.tenor.com/p0HpVhLpXrIAAAAC/how-random-is-that-whats-happening.gif',
    nbViews: 783,
    owner: {
      uuid: '064d0052-a921-48a6-9c06-16da745a9a10',
      firstName: 'Aldonza',
      lastName: 'Ibarra',
      username: 'happybird454',
      avatar: 'https://randomuser.me/api/portraits/women/45.jpg',
    } as SimpleUser,
    dateCreated: '2019-06-15T12:37:23.304Z',
  },
  {
    link: 'https://media.tenor.com/3yFQlMeQrdMAAAAC/spongebob-meme.gif',
    nbViews: 513,
    owner: {
      uuid: 'f2f898d8-fc9c-4923-b78d-909af8480541',
      firstName: 'Vladana',
      lastName: 'Dokić',
      username: 'tinymeercat130',
      avatar: 'https://randomuser.me/api/portraits/women/95.jpg',
    } as SimpleUser,
    dateCreated: '2016-03-10T16:27:15.962Z',
  },
  {
    link: 'https://media.tenor.com/dwoXDFGokUUAAAAC/flying-random.gif',
    nbViews: 71,
    owner: {
      uuid: 'b9977130-fdd7-42f3-abdc-cf9cc6e40156',
      firstName: 'Oona',
      lastName: 'Manni',
      username: 'silverrabbit954',
      avatar: 'https://randomuser.me/api/portraits/women/73.jpg',
    } as SimpleUser,
    dateCreated: '2017-04-30T02:49:49.713Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=750',
    nbLikes: 535,
    owner: {
      uuid: 'd3aa0ff9-04f0-4f79-87da-fdac0496e840',
      firstName: 'Savannah',
      lastName: 'Dunn',
      username: 'redzebra849',
      avatar: 'https://randomuser.me/api/portraits/women/1.jpg',
    } as SimpleUser,
    dateCreated: '2014-02-25T00:20:23.753Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=611',
    nbLikes: 241,
    owner: {
      uuid: '6ea22775-61c7-4ca9-91f1-3b94fd75d326',
      firstName: 'Zahariy',
      lastName: 'Shablenko',
      username: 'yellowbutterfly276',
      avatar: 'https://randomuser.me/api/portraits/men/29.jpg',
    } as SimpleUser,
    dateCreated: '2021-04-25T17:29:03.874Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=177',
    nbLikes: 278,
    owner: {
      uuid: '46338830-edfc-4d99-821f-4aad81cc748d',
      firstName: 'بیتا',
      lastName: 'صدر',
      username: 'yellowfish705',
      avatar: 'https://randomuser.me/api/portraits/women/54.jpg',
    } as SimpleUser,
    dateCreated: '2015-04-20T10:52:57.410Z',
  },
  {
    link: 'https://media.tenor.com/lt0biOTLTUoAAAAC/random-cats.gif',
    nbViews: 493,
    owner: {
      uuid: '7e77ff55-b196-41e3-b815-63e0decec975',
      firstName: 'Jadira',
      lastName: 'Santos',
      username: 'goldengorilla310',
      avatar: 'https://randomuser.me/api/portraits/women/82.jpg',
    } as SimpleUser,
    dateCreated: '2013-07-21T05:16:42.100Z',
  },
  {
    link: 'https://media.tenor.com/fcIdo2cPHR4AAAAC/random-pvz-ts.gif',
    nbViews: 682,
    owner: {
      uuid: '65c5e6d9-411a-4981-bc0c-b42f222e8538',
      firstName: 'Jovica',
      lastName: 'Lapčević',
      username: 'angryrabbit754',
      avatar: 'https://randomuser.me/api/portraits/men/96.jpg',
    } as SimpleUser,
    dateCreated: '2022-03-30T04:50:38.753Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=703',
    nbLikes: 257,
    owner: {
      uuid: '88bdff0b-64aa-44e8-a127-38ab0d2edf88',
      firstName: 'Kayla',
      lastName: 'Moore',
      username: 'lazyduck562',
      avatar: 'https://randomuser.me/api/portraits/women/3.jpg',
    } as SimpleUser,
    dateCreated: '2015-09-16T04:50:41.277Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=265',
    nbLikes: 876,
    owner: {
      uuid: 'e98aa859-ce4f-42c1-bbb3-0d8cc99e2ac2',
      firstName: 'Violeta',
      lastName: 'Rajković',
      username: 'brownbear887',
      avatar: 'https://randomuser.me/api/portraits/women/78.jpg',
    } as SimpleUser,
    dateCreated: '2017-09-08T15:05:53.763Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=220',
    nbLikes: 506,
    owner: {
      uuid: '5fd861be-ef9d-47d0-b21e-3f691f3cafc1',
      firstName: 'Gloria',
      lastName: 'Washington',
      username: 'tinyelephant988',
      avatar: 'https://randomuser.me/api/portraits/women/11.jpg',
    } as SimpleUser,
    dateCreated: '2019-11-21T15:01:41.063Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=297',
    nbLikes: 147,
    owner: {
      uuid: '349c595c-b296-425b-a119-696d76f55761',
      firstName: 'Catherine',
      lastName: 'Franklin',
      username: 'organicgorilla652',
      avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
    } as SimpleUser,
    dateCreated: '2018-07-27T14:10:06.684Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=29',
    nbLikes: 330,
    owner: {
      uuid: 'adb28741-760b-4257-9de8-64d23e597a89',
      firstName: 'Lucas',
      lastName: 'Sanders',
      username: 'tinyleopard454',
      avatar: 'https://randomuser.me/api/portraits/men/15.jpg',
    } as SimpleUser,
    dateCreated: '2018-05-09T16:38:48.668Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=937',
    nbLikes: 242,
    owner: {
      uuid: 'e80cc596-6c7d-4391-a600-2e53a73d7eca',
      firstName: 'Thomas',
      lastName: 'Lowe',
      username: 'angrybird242',
      avatar: 'https://randomuser.me/api/portraits/men/23.jpg',
    } as SimpleUser,
    dateCreated: '2013-09-17T03:57:14.897Z',
  },
  {
    link: 'https://media.tenor.com/cm450yNlsZcAAAAC/random-llama.gif',
    nbViews: 717,
    owner: {
      uuid: '8dd0a596-583e-4175-a451-a864b66d2a5c',
      firstName: 'Jordan',
      lastName: 'Simpson',
      username: 'greenbird695',
      avatar: 'https://randomuser.me/api/portraits/men/25.jpg',
    } as SimpleUser,
    dateCreated: '2013-07-04T18:09:37.306Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=775',
    nbLikes: 931,
    owner: {
      uuid: '399c8faa-e76e-433b-83dc-5a54e2251162',
      firstName: 'Elena',
      lastName: 'Nome',
      username: 'silvergoose673',
      avatar: 'https://randomuser.me/api/portraits/women/89.jpg',
    } as SimpleUser,
    dateCreated: '2015-08-19T17:39:24.695Z',
  },
  {
    link: 'https://media.tenor.com/2fPmrPfx1YgAAAAC/funny-fa-serious-face.gif',
    nbViews: 7,
    owner: {
      uuid: '5d830cdd-a88e-41d6-a781-277a342f5351',
      firstName: 'تینا',
      lastName: 'کریمی',
      username: 'beautifulcat546',
      avatar: 'https://randomuser.me/api/portraits/women/19.jpg',
    } as SimpleUser,
    dateCreated: '2016-07-23T03:05:04.106Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=197',
    nbLikes: 610,
    owner: {
      uuid: 'c126dff7-21f1-474a-bf07-f436be91e771',
      firstName: 'Rigo',
      lastName: 'Cordes',
      username: 'angryladybug964',
      avatar: 'https://randomuser.me/api/portraits/men/39.jpg',
    } as SimpleUser,
    dateCreated: '2018-12-18T09:57:08.836Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=923',
    nbLikes: 481,
    owner: {
      uuid: '3a42fdc6-50ed-4bab-a302-c96ca88f264c',
      firstName: 'Dragana',
      lastName: 'Šerbedžija',
      username: 'blackrabbit653',
      avatar: 'https://randomuser.me/api/portraits/women/66.jpg',
    } as SimpleUser,
    dateCreated: '2014-09-05T05:34:53.375Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=505',
    nbLikes: 724,
    owner: {
      uuid: 'bd34b5c6-b82b-4cf1-b947-fa2b203071e8',
      firstName: 'Mónica',
      lastName: 'Santos',
      username: 'heavyzebra718',
      avatar: 'https://randomuser.me/api/portraits/women/16.jpg',
    } as SimpleUser,
    dateCreated: '2015-01-09T15:51:39.337Z',
  },
  {
    link: 'https://media.tenor.com/G8vpLbIuf20AAAAC/random.gif',
    nbViews: 333,
    owner: {
      uuid: 'ad826a29-d535-4567-bb79-5e919b8f8e56',
      firstName: 'Pelagiya',
      lastName: 'Timoshchuk',
      username: 'organicgoose908',
      avatar: 'https://randomuser.me/api/portraits/women/55.jpg',
    } as SimpleUser,
    dateCreated: '2016-10-12T08:18:40.273Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=99',
    nbLikes: 322,
    owner: {
      uuid: '1eda192f-c70b-45cc-8e6d-fb3675008e3f',
      firstName: 'Anton',
      lastName: 'Sørensen',
      username: 'happyswan981',
      avatar: 'https://randomuser.me/api/portraits/men/41.jpg',
    } as SimpleUser,
    dateCreated: '2017-05-20T04:15:30.905Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=338',
    nbLikes: 97,
    owner: {
      uuid: 'f5e54d39-6cc2-471b-91ca-24c5ac1dfab5',
      firstName: 'Zoey',
      lastName: 'Hamilton',
      username: 'redcat355',
      avatar: 'https://randomuser.me/api/portraits/women/61.jpg',
    } as SimpleUser,
    dateCreated: '2020-08-17T08:39:47.774Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=108',
    nbLikes: 362,
    owner: {
      uuid: '16e9d197-2af2-47ed-ac61-76789deb9275',
      firstName: 'Borimir',
      lastName: 'Klimchuk',
      username: 'happybird520',
      avatar: 'https://randomuser.me/api/portraits/men/51.jpg',
    } as SimpleUser,
    dateCreated: '2021-10-04T02:19:00.000Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=641',
    nbLikes: 432,
    owner: {
      uuid: 'c920902a-d2a9-4b24-b554-da42c4c2f3a4',
      firstName: 'Rosie',
      lastName: 'Matthews',
      username: 'crazyswan683',
      avatar: 'https://randomuser.me/api/portraits/women/77.jpg',
    } as SimpleUser,
    dateCreated: '2012-04-28T22:04:32.668Z',
  },
];
