import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Alert } from 'states/Alert/Alert';

const initialState: Alert = {
  type: undefined,
  message: '',
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert: (state: Alert, action: PayloadAction<Alert>) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
    },

    deleteAlert: (state: Alert) => {
      state.type = undefined;
      state.message = '';
    },
  },
});

export const { setAlert, deleteAlert } = alertSlice.actions;
export default alertSlice.reducer;
