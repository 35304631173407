import { AvatarSmall } from 'global/components/Avatar';
import { Message } from 'states/Message/Message';
import { formatDateTime } from 'utils/formatters';

function MessageLeft({ message }: { message: Message }) {
  return (
    <div className="flex items-center">
      <div>
        <AvatarSmall src={message.from?.avatar} />
      </div>
      <div className="bg-blue-200 rounded-lg p-2 m-1">{message.content}</div>
      <div className="text-xs shrink">{formatDateTime(new Date(message.createdAt))}</div>
    </div>
  );
}

export default MessageLeft;
