import { TextColor } from 'global/enums/colors';
import { IconNotificationDelete } from 'global/lib/icons';
import { useTranslation } from 'react-i18next';

function ButtonUnFollow() {
  const { t } = useTranslation();
  return (
    <div className={`${TextColor.DANGER} p-1 round-full`}>
      <IconNotificationDelete />
    </div>
  );
}

export default ButtonUnFollow;
