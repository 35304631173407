import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { LIMIT_FETCH_MEDIA } from 'global/constants';
import { CardSize } from 'global/enums/sizes';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Image,
  Media,
  Video,
} from 'states/MyMedias/MyMedias';
import { isVideo } from 'utils/check';
import { getGridColsStyleFromCardSize } from 'utils/getStyle';

import {
  getCardImage,
  getCardVideo,
} from '../CardMedia';

interface Props {
  cardSize: CardSize;
  fetchData: (skip: number, limit: number) => Promise<Media[]>;
}

function GridMedia({ cardSize, fetchData }: Props) {
  const gridColStyle = useMemo(() => getGridColsStyleFromCardSize(cardSize), [cardSize]);

  const CardImage = useMemo(() => getCardImage(cardSize), [cardSize]);

  const CardVideo = useMemo(() => getCardVideo(cardSize), [cardSize]);

  const [selectedMedias, setSelectedMedias] = useState<Media[]>([]);

  useEffect(() => {
    fetchData(0, LIMIT_FETCH_MEDIA)
      .then((res) => {
        setSelectedMedias((prev) => [...prev, ...res]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const fetchMoreData = useCallback(() => {
    fetchData(selectedMedias.length, LIMIT_FETCH_MEDIA)
      .then((res) => {
        setSelectedMedias((prev) => [...prev, ...res]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [selectedMedias]);

  console.log('selectedMedias', selectedMedias.length);

  return (
    <InfiniteScroll
      dataLength={selectedMedias.length}
      next={fetchMoreData}
      hasMore={true}
      loader={<h4>Loading...</h4>}
      className={`grid ${gridColStyle} gap-0.5 mt-2 bg-transparent`}
    >
      {selectedMedias.map((media, index) =>
        media ? (
          isVideo(media) ? (
            <CardVideo key={index} video={media as Video} />
          ) : (
            <CardImage key={index} image={media as Image} />
          )
        ) : null
      )}
    </InfiniteScroll>
  );
}

export default GridMedia;

interface GridMediaProps {
  fetchData: (skip: number, limit: number) => Promise<Media[]>;
}

export const GridMediaSS = ({ fetchData }: GridMediaProps) => {
  return <GridMedia cardSize={CardSize.SMALL} fetchData={fetchData} />;
};

export const GridMediaMM = ({ fetchData }: GridMediaProps) => (
  <GridMedia cardSize={CardSize.MEDIUM} fetchData={fetchData} />
);

export const GridMediaLL = ({ fetchData }: GridMediaProps) => (
  <GridMedia cardSize={CardSize.LARGE} fetchData={fetchData} />
);
