import { useMemo } from 'react';

import { TextColor } from 'global/enums/colors';
import useLink from 'global/hooks/usePrivateLink';
import { IconFollowerDefault } from 'global/lib/IconsDefault';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { paths } from 'routes/paths';
import { selectUser } from 'states/User/userSelectors';

import BarItem from '../';

function NbFollowers() {
  const user = useSelector(selectUser);
  const link = useLink(paths.followers.index);
  const location = useLocation();

  const textColor = useMemo(
    () => (location.pathname.endsWith(paths.followers.index) ? TextColor.PRIMARY : ''),
    [location]
  );

  return (
    <BarItem linkTo={link} badge={user.socialStatistics.nbFollowers} textColor={textColor}>
      <IconFollowerDefault />
    </BarItem>
  );
}

export default NbFollowers;
