import { user } from 'global/constants/data-test/people';
import { FormLoginData } from 'pages/public/PageLogin/FormLogin';
import { User } from 'states/User/User';

import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'alert',
  initialState: user,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      return action.payload;
    },
    setLogin: (state, action: PayloadAction<FormLoginData>) => {
      state.username = action.payload.username;
      state.password = action.payload.password;
    },
  },
});

export const { setUser, setLogin } = userSlice.actions;
export default userSlice.reducer;
