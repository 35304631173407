export enum TextColor {
  PRIMARY = 'text-pink-500',
  SECONDARY = 'text-blue-500',
  TERTIARY = 'text-gray-500',
  DANGER = 'text-red-500',
  BLACK = 'text-black',
  WHITE = 'text-white',
}

export enum BgColor {
  PRIMARY = 'bg-pink-500',
  SECONDARY = 'bg-blue-500',
  TERTIARY = 'bg-gray-500',
  BLACK = 'bg-black',
  WHITE = 'bg-white',
}

export enum BorderColor {
  PRIMARY = 'border-pink-500',
  SECONDARY = 'border-blue-500',
  TERTIARY = 'border-gray-500',
}
