import { AvatarLarge } from 'global/components/Avatar';
import { useSelector } from 'react-redux';
import { selectUser } from 'states/User/userSelectors';

function HeaderProfile() {
  const user = useSelector(selectUser);
  return (
    <div className="flex flex-col justify-center items-center gap-2">
      <AvatarLarge src={user.avatar} />
      <div>@{user.username}</div>
      <div>{user.description}</div>
    </div>
  );
}

export default HeaderProfile;
