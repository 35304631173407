import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { LIMIT_FETCH_MESSAGE } from 'global/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getMessagesWithUser } from 'services/messageServices';
import { Message } from 'states/Message/Message';
import { selectFollowingsUsers } from 'states/People/peopleSelectors';
import { selectUser } from 'states/User/userSelectors';

import MessageLeft from './MessageLeft';
import MessageRight from './MessageRight';

function ScrollMessage() {
  const location = useLocation();
  const user = useSelector(selectUser);
  const followings = useSelector(selectFollowingsUsers);
  const toUsername = useMemo(() => location.pathname.split('/')[3], [location]);

  const toUser = useMemo(
    () => followings.find((user) => user.username === toUsername),
    [toUsername, followings]
  );

  const [selectedMessage, setSelectedMessage] = useState<Message[]>([]);

  useEffect(() => {
    if (toUser) {
      getMessagesWithUser(user, toUser, 0, LIMIT_FETCH_MESSAGE)
        .then((res) => {
          setSelectedMessage((prev) => [...prev, ...res]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  const fetchMoreData = useCallback(() => {
    if (toUser) {
      getMessagesWithUser(user, toUser, selectedMessage.length, LIMIT_FETCH_MESSAGE)
        .then((res) => {
          setSelectedMessage((prev) => [...prev, ...res]);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selectedMessage]);

  return (
    <div id="scrollableDiv" className="h-screen overflow-auto flex flex-col-reverse">
      <InfiniteScroll
        dataLength={selectedMessage.length}
        next={fetchMoreData}
        hasMore={true}
        loader={<h4>Loading...</h4>}
        inverse={true}
        pullDownToRefresh={true}
        refreshFunction={fetchMoreData}
        className="flex flex-col-reverse"
        scrollableTarget="scrollableDiv"
      >
        {selectedMessage.map((message, index) => {
          if (message.from?.username === user?.username) {
            return <MessageRight key={index} message={message} />;
          } else {
            return <MessageLeft key={index} message={message} />;
          }
        })}
      </InfiniteScroll>
    </div>
  );
}

export default ScrollMessage;
