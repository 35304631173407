import { Media } from 'states/MyMedias/MyMedias';

import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { OthersMedias } from './OthersMedias';

const initialState: OthersMedias = {
  publicMedias: [],
  followingsMedias: [],
};

export const othersMediasSlice = createSlice({
  name: 'myMedias',
  initialState,
  reducers: {
    addPublicMedias: (state: OthersMedias, action: PayloadAction<Media[]>) => {
      state.publicMedias.push(...action.payload);
    },
    addFollowingsMedias: (state: OthersMedias, action: PayloadAction<Media[]>) => {
      state.followingsMedias.push(...action.payload);
    },
  },
});

export const { addPublicMedias, addFollowingsMedias } = othersMediasSlice.actions;
export default othersMediasSlice.reducer;
