export enum AvatarSize {
  SMALL = 'w-6 h-6',
  MEDIUM = 'w-10 h-10',
  LARGE = 'w-16 h-16',
}

export enum CardSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
export enum IconSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}
