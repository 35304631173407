import { useMemo } from 'react';

import { AvatarSmall } from 'global/components/Avatar';
import { MediaFormat } from 'global/enums';
import { Media } from 'states/MyMedias/MyMedias';

import CardMedia from '../';
import { BadgeMediaTopFullScreenGap } from '../BadgeMedia';

interface Props {
  media: Media;
  children: JSX.Element;
}

function CardMediaFullScreen({ media, children }: Props) {
  const fullName = useMemo(
    () => `${media.owner?.firstName ?? 'Unkown'} ${media.owner?.lastName ?? 'User'}`,
    [media.owner]
  );
  return (
    <CardMedia src={media.link} form={MediaFormat.FULLSCREEN}>
      <>
        <BadgeMediaTopFullScreenGap>
          <>
            <AvatarSmall src={media.owner?.avatar} />
            <span className="bg-black py-1 px-2 rounded-md font-bold">{fullName}</span>
          </>
        </BadgeMediaTopFullScreenGap>
        {children}
      </>
    </CardMedia>
  );
}

export default CardMediaFullScreen;
