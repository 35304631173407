import React from 'react';

import { supportedLngs } from 'langs/config';
import LayoutPrimary from 'layouts/LayoutPrimary';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Lang,
  Theme,
} from 'states/Settings/Settings';
import {
  selectLang,
  selectTheme,
} from 'states/Settings/settingSelectors';
import {
  setLang,
  setTheme,
} from 'states/Settings/settingSlice';

const themes = Object.values(Theme);

function PageSettings() {
  const { t, i18n } = useTranslation();
  const lang = useSelector(selectLang);
  const theme = useSelector(selectTheme);
  const dispatch = useDispatch();

  const handleLangChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    dispatch(setLang(value as Lang));
    i18n.changeLanguage(value);
  };

  const handleThemeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setTheme(e.target.value as Theme));
  };
  return (
    <LayoutPrimary>
      <div className="flex flex-col items-center justify-between space-y-5">
        <div className="flex items-center justify-between space-x-5">
          <div className="block mb-2 text-sm font-medium">{t('common:language')}</div>
          <select
            className="px-2 py-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={lang}
            onChange={handleLangChange}
          >
            {supportedLngs.map((lng) => (
              <option key={lng} value={lng}>
                {lng}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center justify-between space-x-5">
          <div className="block mb-2 text-sm font-medium">{t('common:theme')}</div>
          <select
            className="px-2 py-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={theme}
            onChange={handleThemeChange}
          >
            {themes.map((theme) => (
              <option key={theme} value={theme}>
                {theme}
              </option>
            ))}
          </select>
        </div>
      </div>
    </LayoutPrimary>
  );
}

export default PageSettings;
