import { GridCols } from 'global/enums';
import {
  AvatarSize,
  CardSize,
  IconSize,
} from 'global/enums/sizes';

export const getGridColsStyleFromCardSize = (cardSize?: CardSize) => {
  switch (cardSize) {
    case CardSize.LARGE:
      return GridCols.ONE;
    case CardSize.MEDIUM:
      return GridCols.THREE;
    default:
      return GridCols.FOUR;
  }
};

export const getIconSizeFromAvatarSize = (avatarSize?: AvatarSize) => {
  switch (avatarSize) {
    case AvatarSize.LARGE:
      return IconSize.LARGE;
    case AvatarSize.MEDIUM:
      return IconSize.MEDIUM;
    default:
      return IconSize.SMALL;
  }
};
