import NbFollowers from './BarItem/NbFollowers';
import NbFollowing from './BarItem/NbFollowing';
import NbLikes from './BarItem/NbLikes';
import NbViews from './BarItem/NbViews';

function BarStatistics() {
  return (
    <div className="grid grid-cols-4 justify-center justify-items-center items-center text-xs">
      <NbFollowing />
      <NbFollowers />
      <NbLikes />
      <NbViews />
    </div>
  );
}

export default BarStatistics;
