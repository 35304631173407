import LayoutPrimary from 'layouts/LayoutPrimary';
import { useSelector } from 'react-redux';
import { getFollowingsUserWithLastMessage } from 'services/messageServices';
import { selectFollowingsUsers } from 'states/People/peopleSelectors';

import TableInboxScroll from './TableInboxScroll';

function PageInboxSummary() {
  const followings = useSelector(selectFollowingsUsers);
  const fetchData = () => getFollowingsUserWithLastMessage(0, 10);
  return (
    <LayoutPrimary>
      <TableInboxScroll fetchData={fetchData} />
    </LayoutPrimary>
  );
}

export default PageInboxSummary;
