import { TextColor } from 'global/enums/colors';

import {
  IconMic,
  IconSearch,
  IconSend,
} from './icons';

export const IconMicTertiary = () => <IconMic className={TextColor.TERTIARY} />;
export const IconSearchTertiary = () => <IconSearch className={TextColor.TERTIARY} />;
export const IconSendTertiary = () => <IconSend className={TextColor.TERTIARY} />;
