export const paths = {
  index: '/',
  home: '/home',
  login: '/login',
  signup: '/signup',
  inbox: '/inbox',
  people: '/people',
  followers: {
    index: '/followers',
  },
  followings: {
    index: '/followings',
    medias: '/followings/medias',
  },
  profile: {
    index: '/profile',
    myVideos: '/profile/my-videos',
    myPhotos: '/profile/my-photos',
    sharedMedias: '/profile/shared-medias',
    likedMedias: '/profile/liked-medias',
    likes: '/profile/likes',
    views: '/profile/views',
    settings: '/profile/settings',
    edit: '/profile/edit',
  },
  settings: {
    index: '/settings',
  },
};
