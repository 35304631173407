import HeaderSecondary from 'layouts/components/HeaderSecondary';
import ThemeWrapper from 'layouts/Theme';

function LayoutSecondary({ children }: { children: JSX.Element }) {
  return (
    <ThemeWrapper>
      <div
        className="relative flex flex-col items-center w-full h-full min-h-screen justify-center overflow-auto"
        style={{
          backgroundImage: `url(https://tailwindadmin.netlify.app/dist/images/login-new.jpeg)`,
        }}
      >
        <HeaderSecondary />
        <div className="flex flex-col h-full min-h-screen">{children}</div>
      </div>
    </ThemeWrapper>
  );
}

export default LayoutSecondary;
