import {
  KeyboardEvent,
  useCallback,
} from 'react';

import {
  IconMicTertiary,
  IconSearchTertiary,
} from 'global/lib/IconsTertiary';
import {
  SubmitHandler,
  useForm,
} from 'react-hook-form';

interface Inputs {
  tag: string;
}

function BarSearch({ placeholder }: { placeholder?: string }) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = useCallback((data) => console.log(data), []);

  const handleKeyUp = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSubmit(onSubmit);
      }
    },
    [handleSubmit, onSubmit]
  );

  return (
    <form
      className="sticky top-10 left-0 z-10 flex items-center py-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="relative w-full">
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <IconSearchTertiary />
        </div>
        <input
          type="text"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder={placeholder}
          {...register('tag', { required: true })}
          onKeyUp={handleKeyUp}
        />
        <button type="button" className="flex absolute inset-y-0 right-0 items-center pr-3">
          <IconMicTertiary />
        </button>
      </div>
    </form>
  );
}

export default BarSearch;
