import {
  useEffect,
  useState,
} from 'react';

import { useLocation } from 'react-router-dom';
import {
  isProfileTabBookmark,
  isProfileTabLikes,
  isProfileTabPhotos,
  isProfileTabShared,
  isProfileTabVideos,
  isProfileTabViews,
} from 'utils/pathCheck';

import GridBookmarkedMedias from './GridBookmarkedMedias';
import GridMyPhotosByDate from './GridMyPhotosByDate';
import GridMyPhotosByNbLikes from './GridMyPhotosByNbLikes';
import GridMyVideosByDate from './GridMyVideosByDate';
import GridMyVideosByNbViews from './GridMyVideosByNbViews';
import GridSharedMedias from './GridSharedMedias';

function SectionMedia() {
  const location = useLocation();
  const [gridMedia, setGridMedia] = useState<JSX.Element>();

  useEffect(() => {
    if (isProfileTabViews(location.pathname)) {
      setGridMedia(<GridMyVideosByNbViews />);
    } else if (isProfileTabLikes(location.pathname)) {
      setGridMedia(<GridMyPhotosByNbLikes />);
    } else if (isProfileTabVideos(location.pathname)) {
      setGridMedia(<GridMyVideosByDate />);
    } else if (isProfileTabPhotos(location.pathname)) {
      setGridMedia(<GridMyPhotosByDate />);
    } else if (isProfileTabBookmark(location.pathname)) {
      setGridMedia(<GridBookmarkedMedias />);
    } else if (isProfileTabShared(location.pathname)) {
      setGridMedia(<GridSharedMedias />);
    }
  }, [location.pathname]);

  return <>{gridMedia}</>;
}

export default SectionMedia;
