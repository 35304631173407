import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import { LIMIT_FETCH_USER } from 'global/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { SimpleUser } from 'states/User/User';

import { PayloadAction } from '@reduxjs/toolkit';

import RowPerson from './RowPerson';

function TablePeopleScroll({
  fetchData,
  dispatchAction,
}: {
  fetchData: (skip: number, limit: number) => Promise<SimpleUser[]>;
  dispatchAction: (payload: SimpleUser[]) => PayloadAction<SimpleUser[]>;
}) {
  const dispatch = useDispatch();

  const [selectedPeople, setSelectedPeople] = useState<SimpleUser[]>([]);

  useEffect(() => {
    fetchData(0, LIMIT_FETCH_USER)
      .then((res) => {
        setSelectedPeople((prev) => [...prev, ...res]);
        dispatch(dispatchAction(res));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const fetchMoreData = useCallback(() => {
    fetchData(selectedPeople.length, LIMIT_FETCH_USER)
      .then((res) => {
        setSelectedPeople((prev) => [...prev, ...res]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [selectedPeople]);

  return (
    <InfiniteScroll
      dataLength={selectedPeople.length}
      next={fetchMoreData}
      hasMore={true}
      loader={<h4>Loading...</h4>}
      className="flex flex-col space-y-2 justify-between"
    >
      {selectedPeople.map((person, index) => (
        <RowPerson key={index} person={person} />
      ))}
    </InfiniteScroll>
  );
}

export default TablePeopleScroll;
