import LayoutPrimary from 'layouts/LayoutPrimary';

import BarStatistics from './BarStatistics';
import HeaderProfile from './HeaderProfile';
import NavBarMedia from './NavBarMedia';
import SectionMedia from './SectionMedia';

function PageProfile() {
  return (
    <LayoutPrimary>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-4">
          <HeaderProfile />
          <BarStatistics />
        </div>
        <div className="flex flex-col">
          <NavBarMedia />
          <SectionMedia />
        </div>
      </div>
    </LayoutPrimary>
  );
}

export default PageProfile;
