import { IconLove } from 'global/lib/icons';
import { Image } from 'states/MyMedias/MyMedias';

import CardMediaFullScreen from '../';
import { BadgeMediaBottomFullScreenGap } from '../../BadgeMedia';

function CardImageFullScreen({ image }: { image: Image }) {
  return (
    <CardMediaFullScreen media={image}>
      <BadgeMediaBottomFullScreenGap>
        <>
          <IconLove fontSize="small" />
          <span>{image.nbLikes}</span>
        </>
      </BadgeMediaBottomFullScreenGap>
    </CardMediaFullScreen>
  );
}

export default CardImageFullScreen;
