import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  Lang,
  Settings,
  Theme,
} from './Settings';

const initialState: Settings = {
  lang: Lang.EN,
  theme: Theme.LIGHT,
};

export const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setLang: (state: Settings, action: PayloadAction<Lang>) => {
      state.lang = action.payload;
    },
    setTheme: (state: Settings, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    },
  },
});

export const { setLang, setTheme } = settingSlice.actions;
export default settingSlice.reducer;
