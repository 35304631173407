import { useMemo } from 'react';

import { AvatarSmall } from 'global/components/Avatar';
import { Media } from 'states/MyMedias/MyMedias';
import { cutStringForSmallBadge } from 'utils/formatters';

import CardMedia from '../';
import { BadgeMediaTopMediumGap } from '../BadgeMedia';

interface Props {
  media: Media;
  children: JSX.Element;
  handleClick?: () => void;
}

function CardMediaMedium({ media, children, handleClick }: Props) {
  const name = useMemo(
    () =>
      cutStringForSmallBadge(
        `${media.owner?.firstName ?? 'Unkown'} ${media.owner?.lastName ?? 'User'}`
      ),
    [media.owner?.firstName, media.owner?.lastName]
  );
  return (
    <CardMedia src={media.link} handleClick={handleClick}>
      <>
        <BadgeMediaTopMediumGap>
          <>
            <AvatarSmall src={media.owner?.avatar} />
            <span className="bg-black py-0.5 px-2 rounded-md opacity-50">{name}</span>
          </>
        </BadgeMediaTopMediumGap>
        {children}
      </>
    </CardMedia>
  );
}

export default CardMediaMedium;
