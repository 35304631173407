import { IconLove } from 'global/lib/icons';
import { Image } from 'states/MyMedias/MyMedias';

import CardMediaLarge from '../';
import { BadgeMediaBottomLargeGap } from '../../BadgeMedia';
import CardImageHidden from '../../CardMediaHidden/CardImageHidden';

function CardImageLarge({ image }: { image: Image }) {
  return (
    <CardImageHidden image={image}>
      <CardMediaLarge media={image}>
        <BadgeMediaBottomLargeGap>
          <>
            <IconLove fontSize="small" />
            <span>{image.nbLikes}</span>
          </>
        </BadgeMediaBottomLargeGap>
      </CardMediaLarge>
    </CardImageHidden>
  );
}

export default CardImageLarge;
