import LayoutPrimary from 'layouts/LayoutPrimary';

import InputMessage from './InputMessage';
import ScrollMessage from './ScrollMessage';

function PageMessages() {
  return (
    <LayoutPrimary>
      <>
        <ScrollMessage />
        <InputMessage />
      </>
    </LayoutPrimary>
  );
}

export default PageMessages;
