import { Video } from 'states/MyMedias/MyMedias';
import { SimpleUser } from 'states/User/User';

export const myVideos: Video[] = [
  {
    link: 'https://media.tenor.com/NRDMhWnPYi8AAAAC/awkward-derp.gif',
    nbViews: 542,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2012-05-18T13:23:41.137Z',
  },
  {
    link: 'https://media.tenor.com/_5qd8_05sDUAAAAC/pinkant-addison.gif',
    nbViews: 702,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2018-04-27T15:37:42.961Z',
  },
  {
    link: 'https://media.tenor.com/zhiy4iDzU4MAAAAC/random-dancing.gif',
    nbViews: 624,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2016-03-16T16:23:13.737Z',
  },
  {
    link: 'https://media.tenor.com/yN7612R5lOUAAAAC/random.gif',
    nbViews: 796,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2022-09-18T04:20:21.019Z',
  },
  {
    link: 'https://media.tenor.com/AC62zg5nPH4AAAAC/random.gif',
    nbViews: 442,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2014-07-29T19:43:47.232Z',
  },
  {
    link: 'https://media.tenor.com/AfbRu9zthJsAAAAC/a-really-random-thing-tracy-kiss.gif',
    nbViews: 765,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2016-08-17T21:17:48.860Z',
  },
  {
    link: 'https://media.tenor.com/RtJifRTjOHEAAAAC/dancing-random.gif',
    nbViews: 250,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2021-07-20T19:01:49.445Z',
  },
  {
    link: 'https://media.tenor.com/kNj3X8Iv1-EAAAAC/random-random-du-chateau.gif',
    nbViews: 323,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2017-10-07T17:34:58.557Z',
  },
  {
    link: 'https://media.tenor.com/wQ1pTV-ugXwAAAAC/gah-weird.gif',
    nbViews: 720,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2017-07-17T15:42:09.579Z',
  },
  {
    link: 'https://media.tenor.com/_l2HP4a_chUAAAAC/arashi-rocket-league.gif',
    nbViews: 513,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2020-11-19T06:38:30.313Z',
  },
  {
    link: 'https://media.tenor.com/lt0biOTLTUoAAAAC/random-cats.gif',
    nbViews: 746,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2014-02-17T23:10:03.545Z',
  },
  {
    link: 'https://media.tenor.com/cm450yNlsZcAAAAC/random-llama.gif',
    nbViews: 888,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2016-02-14T05:52:58.946Z',
  },
  {
    link: 'https://media.tenor.com/0C3wYUtAb78AAAAC/david-kubrt-kubrt.gif',
    nbViews: 687,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2022-04-08T01:23:09.585Z',
  },
  {
    link: 'https://media.tenor.com/H_Htc8iPURMAAAAC/aot.gif',
    nbViews: 951,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2021-11-28T09:17:28.847Z',
  },
  {
    link: 'https://media.tenor.com/mLuTCIhpCVkAAAAC/pinkant-venomgoinglimited.gif',
    nbViews: 811,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2022-09-05T10:56:11.450Z',
  },
  {
    link: 'https://media.tenor.com/xpfRLZxjvVwAAAAC/random.gif',
    nbViews: 333,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2013-01-15T06:27:54.114Z',
  },
  {
    link: 'https://media.tenor.com/2fPmrPfx1YgAAAAC/funny-fa-serious-face.gif',
    nbViews: 376,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2017-08-23T01:00:49.812Z',
  },
  {
    link: 'https://media.tenor.com/2NzI0HjUdVoAAAAC/wiggle-random.gif',
    nbViews: 296,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2015-03-17T14:38:21.770Z',
  },
  {
    link: 'https://media.tenor.com/YLsSKM8xWTwAAAAC/random.gif',
    nbViews: 349,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2021-06-14T04:43:01.192Z',
  },
  {
    link: 'https://media.tenor.com/p0HpVhLpXrIAAAAC/how-random-is-that-whats-happening.gif',
    nbViews: 222,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2016-01-08T08:07:09.308Z',
  },
  {
    link: 'https://media.tenor.com/fcIdo2cPHR4AAAAC/random-pvz-ts.gif',
    nbViews: 710,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2013-03-24T20:20:09.026Z',
  },
  {
    link: 'https://media.tenor.com/3yFQlMeQrdMAAAAC/spongebob-meme.gif',
    nbViews: 754,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2022-03-14T05:04:15.648Z',
  },
  {
    link: 'https://media.tenor.com/fFntTHJYFPMAAAAC/random.gif',
    nbViews: 896,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2022-07-18T22:37:15.915Z',
  },
  {
    link: 'https://media.tenor.com/G8vpLbIuf20AAAAC/random.gif',
    nbViews: 690,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2021-02-10T20:22:06.835Z',
  },
  {
    link: 'https://media.tenor.com/qj3vwUKiCvYAAAAC/random-funny.gif',
    nbViews: 913,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2013-06-02T06:45:36.744Z',
  },
  {
    link: 'https://media.tenor.com/fuGF9MgQ4uoAAAAC/bored-random.gif',
    nbViews: 179,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2014-08-30T02:19:20.087Z',
  },
  {
    link: 'https://media.tenor.com/13t9cpC8Ou0AAAAC/random.gif',
    nbViews: 15,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2016-05-26T20:46:17.704Z',
  },
  {
    link: 'https://media.tenor.com/d8H-cnFIHPoAAAAC/weird-cat.gif',
    nbViews: 871,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2017-07-19T20:21:06.712Z',
  },
  {
    link: 'https://media.tenor.com/p0HpVhLpXrIAAAAC/how-random-is-that-whats-happening.gif',
    nbViews: 0,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2019-05-29T18:47:49.973Z',
  },
  {
    link: 'https://media.tenor.com/NRDMhWnPYi8AAAAC/awkward-derp.gif',
    nbViews: 382,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2017-03-10T16:25:29.669Z',
  },
  {
    link: 'https://media.tenor.com/y4QbP8-Oo6gAAAAC/laughing-green.gif',
    nbViews: 154,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2022-03-23T23:46:39.674Z',
  },
  {
    link: 'https://media.tenor.com/s4do6pySWMQAAAAC/random.gif',
    nbViews: 60,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2013-10-04T22:27:10.710Z',
  },
  {
    link: 'https://media.tenor.com/37AZ2UJdcVUAAAAC/nur-random.gif',
    nbViews: 247,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2014-09-26T21:11:37.151Z',
  },
  {
    link: 'https://media.tenor.com/wQ1pTV-ugXwAAAAC/gah-weird.gif',
    nbViews: 429,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2014-01-20T06:59:48.536Z',
  },
  {
    link: 'https://media.tenor.com/AfbRu9zthJsAAAAC/a-really-random-thing-tracy-kiss.gif',
    nbViews: 146,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2018-05-17T22:24:57.868Z',
  },
  {
    link: 'https://media.tenor.com/0C3wYUtAb78AAAAC/david-kubrt-kubrt.gif',
    nbViews: 36,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2017-09-28T06:05:30.910Z',
  },
  {
    link: 'https://media.tenor.com/lp728VCr1FwAAAAC/creepy-thomas.gif',
    nbViews: 33,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2016-02-02T13:53:19.371Z',
  },
  {
    link: 'https://media.tenor.com/xpfRLZxjvVwAAAAC/random.gif',
    nbViews: 203,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2020-04-08T20:24:10.109Z',
  },
  {
    link: 'https://media.tenor.com/jqS9Bb71T6YAAAAC/random.gif',
    nbViews: 196,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2017-02-16T04:47:30.143Z',
  },
  {
    link: 'https://media.tenor.com/YLsSKM8xWTwAAAAC/random.gif',
    nbViews: 48,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2019-09-10T08:09:02.314Z',
  },
  {
    link: 'https://media.tenor.com/sRx6btX50RcAAAAC/cow-utter.gif',
    nbViews: 29,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2019-11-04T06:29:59.831Z',
  },
  {
    link: 'https://media.tenor.com/kNj3X8Iv1-EAAAAC/random-random-du-chateau.gif',
    nbViews: 687,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2022-06-13T07:26:36.687Z',
  },
  {
    link: 'https://media.tenor.com/AC62zg5nPH4AAAAC/random.gif',
    nbViews: 493,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2014-05-25T07:28:10.803Z',
  },
  {
    link: 'https://media.tenor.com/TFKrzUaThWUAAAAC/pizza-sexy.gif',
    nbViews: 228,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2020-08-24T00:59:39.292Z',
  },
  {
    link: 'https://media.tenor.com/VQezvBxsbuIAAAAC/randomduchateau-random.gif',
    nbViews: 704,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2022-04-30T05:54:03.723Z',
  },
  {
    link: 'https://media.tenor.com/zqi2DFUitoYAAAAC/cat-indiana.gif',
    nbViews: 350,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2015-12-16T22:34:56.560Z',
  },
  {
    link: 'https://media.tenor.com/_l2HP4a_chUAAAAC/arashi-rocket-league.gif',
    nbViews: 23,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2016-09-06T07:06:44.929Z',
  },
  {
    link: 'https://media.tenor.com/G8vpLbIuf20AAAAC/random.gif',
    nbViews: 309,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2014-12-26T01:24:43.707Z',
  },
  {
    link: 'https://media.tenor.com/k3_7ODBzTE4AAAAC/hi-jim-carrey.gif',
    nbViews: 547,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2022-09-15T21:59:55.686Z',
  },
  {
    link: 'https://media.tenor.com/KA8tXUku8-QAAAAC/hugs-hugging.gif',
    nbViews: 392,
    owner: {
      uuid: '135fe288-92a5-4791-93b3-3adf6b827363',
      firstName: 'Light',
      lastName: 'Pham',
      username: 'lightpham',
      avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
    } as SimpleUser,
    dateCreated: '2020-02-24T18:22:52.613Z',
  },
];
