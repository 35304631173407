import LayoutPrimary from 'layouts/LayoutPrimary';
import BarSearch from 'pages/components/BarSearch';
import { GridMediaMM } from 'pages/components/GridMedia';
import { getPublicMediasOrderByDate } from 'services/mediaServices';

function PageHome() {
  return (
    <LayoutPrimary>
      <>
        <BarSearch placeholder="Search tags" />
        <GridMediaMM fetchData={getPublicMediasOrderByDate} />
      </>
    </LayoutPrimary>
  );
}

export default PageHome;
