import LayoutPrimary from 'layouts/LayoutPrimary';
import TablePeopleScroll from 'pages/components/TablePeopleScroll';
import { getFollowersUsers } from 'services/userServices';
import { addFollowersUsers } from 'states/People/peopleSlice';

function PageFollowersList() {
  return (
    <LayoutPrimary>
      <div className="flex flex-col space-y-2 p-3">
        <TablePeopleScroll fetchData={getFollowersUsers} dispatchAction={addFollowersUsers} />
      </div>
    </LayoutPrimary>
  );
}

export default PageFollowersList;
