import { IconPlay } from 'global/lib/icons';
import { Video } from 'states/MyMedias/MyMedias';

import { BadgeMediaBottomMediumGap } from '../../BadgeMedia';
import CardVideoHidden from '../../CardMediaHidden/CardVideoHidden';
import CardMediaMedium from '../../CardMediaMedium';

function CardVideoMedium({ video }: { video: Video }) {
  return (
    <CardVideoHidden video={video}>
      <CardMediaMedium media={video}>
        <BadgeMediaBottomMediumGap>
          <>
            <IconPlay fontSize="small" />
            <span>{video.nbViews}</span>
          </>
        </BadgeMediaBottomMediumGap>
      </CardMediaMedium>
    </CardVideoHidden>
  );
}

export default CardVideoMedium;
