import Footer from 'layouts/components/Footer';
import Header from 'layouts/components/Header';
import ThemeWrapper from 'layouts/Theme';
import ButtonScrollToTop from 'pages/components/ButtonScrollToTop';

function LayoutPrimary({ children }: { children: JSX.Element }) {
  return (
    <ThemeWrapper>
      <div className="flex items-center w-full h-full min-h-screen justify-center">
        {/* <BarLeft /> */}
        <div className="w-full md:w-2/3 lg:w-1/2 h-full min-h-screen">
          <div className="flex flex-col gap-1 justify-between">
            <Header />
            <div className="flex flex-col h-full min-h-screen px-0.5">{children}</div>
            <ButtonScrollToTop />
            <Footer />
          </div>
        </div>
        {/* <BarRight /> */}
      </div>
    </ThemeWrapper>
  );
}

export default LayoutPrimary;
