import React from 'react';

import { Image } from 'states/MyMedias/MyMedias';

import CardMediaHidden from '../';
import CardImageFullScreen from '../../CardMediaFullScreen/CardImageFullScreen';

function CardImageHidden({ image, children }: { image: Image; children: JSX.Element }) {
  return (
    <CardMediaHidden hiddenComponent={<CardImageFullScreen image={image} />} children={children} />
  );
}

export default CardImageHidden;
