import { SimpleUser } from 'states/User/User';

export const followingsUsers: SimpleUser[] = [
  {
    uuid: '9f26c7e2-1ea2-47e2-9d94-45432739c97a',
    firstName: 'Latife',
    lastName: 'Özdoğan',
    username: 'goldenfish420',
    avatar: 'https://randomuser.me/api/portraits/women/14.jpg',
  },
  {
    uuid: '723c92d5-4122-4f2b-833c-1ce55640e286',
    firstName: 'Katrine',
    lastName: 'Hansen',
    username: 'silverbutterfly782',
    avatar: 'https://randomuser.me/api/portraits/women/33.jpg',
  },
  {
    uuid: '8f7e5bf1-8968-4dc8-9acc-3382d7c84fee',
    firstName: 'Jim',
    lastName: 'Wilson',
    username: 'smallpeacock272',
    avatar: 'https://randomuser.me/api/portraits/men/16.jpg',
  },
  {
    uuid: '9bfb78b5-4d7f-41c7-9c98-62829300099b',
    firstName: 'Mabel',
    lastName: 'Van Aalst',
    username: 'lazygorilla217',
    avatar: 'https://randomuser.me/api/portraits/women/57.jpg',
  },
  {
    uuid: '1aef6bf0-0c17-4544-980b-d710fb7b901f',
    firstName: 'آرسین',
    lastName: 'موسوی',
    username: 'angrypeacock876',
    avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
  },
  {
    uuid: '6d47cefd-bcda-4bde-8cdb-888461346499',
    firstName: 'Heather',
    lastName: 'Spencer',
    username: 'sadleopard557',
    avatar: 'https://randomuser.me/api/portraits/women/41.jpg',
  },
  {
    uuid: '2bb8c7da-bc4b-48fd-84eb-2f063c40f3db',
    firstName: 'Vitomir',
    lastName: 'Pryadko',
    username: 'tinypanda236',
    avatar: 'https://randomuser.me/api/portraits/men/37.jpg',
  },
  {
    uuid: '79240359-af8e-40ed-ae0f-2fcb7f5267f7',
    firstName: 'Hilda',
    lastName: 'Narváez',
    username: 'yellowcat960',
    avatar: 'https://randomuser.me/api/portraits/women/46.jpg',
  },
  {
    uuid: '27bfcb1d-0a8e-4d0c-b70f-a47c48bcb523',
    firstName: 'الینا',
    lastName: 'علیزاده',
    username: 'yellowmouse379',
    avatar: 'https://randomuser.me/api/portraits/women/28.jpg',
  },
  {
    uuid: '7ad80212-b0c3-44cc-9cfb-f19e0e37aee6',
    firstName: 'Jacinto',
    lastName: 'Olivares',
    username: 'yellowpanda991',
    avatar: 'https://randomuser.me/api/portraits/men/90.jpg',
  },
  {
    uuid: 'ab99b993-2d1b-4fae-9414-b52f3ec56c61',
    firstName: 'مریم',
    lastName: 'نكو نظر',
    username: 'brownpanda388',
    avatar: 'https://randomuser.me/api/portraits/women/21.jpg',
  },
  {
    uuid: '1b044038-e0c9-4227-b390-18a7a34af7b9',
    firstName: 'Augustas',
    lastName: 'Sandvold',
    username: 'organicbear206',
    avatar: 'https://randomuser.me/api/portraits/men/45.jpg',
  },
  {
    uuid: 'fdf429c8-c352-4bc8-92b7-65455800bb1b',
    firstName: 'Viivi',
    lastName: 'Salonen',
    username: 'heavygorilla436',
    avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
  },
  {
    uuid: '08338307-b5ba-4fa6-82f3-b076e2456c61',
    firstName: 'Abby',
    lastName: 'Lambert',
    username: 'brownpanda124',
    avatar: 'https://randomuser.me/api/portraits/women/88.jpg',
  },
  {
    uuid: '1b64dbb2-5276-4e56-8816-4ede03f294ab',
    firstName: 'Alexis',
    lastName: 'Ouellet',
    username: 'bigkoala339',
    avatar: 'https://randomuser.me/api/portraits/men/87.jpg',
  },
  {
    uuid: '601987ba-9564-403e-b136-27291f85ad43',
    firstName: 'Gerry',
    lastName: 'Adams',
    username: 'bluegorilla244',
    avatar: 'https://randomuser.me/api/portraits/men/57.jpg',
  },
  {
    uuid: 'b5f7fcdd-2745-41d3-959b-396d923fe031',
    firstName: 'Nolan',
    lastName: 'Graham',
    username: 'organicpanda273',
    avatar: 'https://randomuser.me/api/portraits/men/87.jpg',
  },
  {
    uuid: 'f0bf7387-eb3d-45ed-aed0-654701c2bd11',
    firstName: 'Çiğdem',
    lastName: 'Bil',
    username: 'angrygorilla400',
    avatar: 'https://randomuser.me/api/portraits/women/5.jpg',
  },
  {
    uuid: 'a3f791ed-3d2b-4dc9-9d52-55e59415cf1b',
    firstName: 'Potishana',
    lastName: 'Gulyanickiy',
    username: 'blackfrog404',
    avatar: 'https://randomuser.me/api/portraits/women/59.jpg',
  },
  {
    uuid: '591f763e-4a70-4ae7-a7d6-b3a4996fe390',
    firstName: 'Eren',
    lastName: 'Küçükler',
    username: 'tinygoose741',
    avatar: 'https://randomuser.me/api/portraits/men/31.jpg',
  },
  {
    uuid: '075c734b-56ef-4757-90a5-a151d98afb1e',
    firstName: 'Arthur',
    lastName: 'James',
    username: 'purplegoose668',
    avatar: 'https://randomuser.me/api/portraits/men/10.jpg',
  },
  {
    uuid: '072ac0ba-a19c-4139-97f9-7266ecff431f',
    firstName: 'Agustín',
    lastName: 'León',
    username: 'whitemeercat526',
    avatar: 'https://randomuser.me/api/portraits/men/38.jpg',
  },
  {
    uuid: 'a9e7103f-9a82-4313-9015-58be97088cf0',
    firstName: 'Manel',
    lastName: 'Moraes',
    username: 'bigfrog385',
    avatar: 'https://randomuser.me/api/portraits/men/93.jpg',
  },
  {
    uuid: '4e6c2d51-8c28-444e-ae0b-02da55cf1770',
    firstName: 'David',
    lastName: 'Soler',
    username: 'angrypanda107',
    avatar: 'https://randomuser.me/api/portraits/men/63.jpg',
  },
  {
    uuid: 'dd4fb840-f9f7-4219-94e9-9295c45ac001',
    firstName: 'Selene',
    lastName: 'Rodrigues',
    username: 'organicmouse245',
    avatar: 'https://randomuser.me/api/portraits/women/73.jpg',
  },
  {
    uuid: '9c6c0ea7-c82d-47af-b384-c25372c94dc8',
    firstName: 'Meral',
    lastName: 'Tokgöz',
    username: 'crazyrabbit240',
    avatar: 'https://randomuser.me/api/portraits/women/47.jpg',
  },
  {
    uuid: '65bd3bd0-63fa-4f94-9ec5-e7ff7bee5bae',
    firstName: 'Harry',
    lastName: 'Harrison',
    username: 'smallfrog916',
    avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
  },
  {
    uuid: 'b17ca90c-03ce-4f52-9fac-ad77a0b3e36b',
    firstName: 'Michelle',
    lastName: 'Carlson',
    username: 'smallmouse884',
    avatar: 'https://randomuser.me/api/portraits/women/63.jpg',
  },
  {
    uuid: 'dc2fed30-e407-4ba7-924f-b255f359b469',
    firstName: 'Raúl',
    lastName: 'Rojas',
    username: 'crazywolf740',
    avatar: 'https://randomuser.me/api/portraits/men/37.jpg',
  },
  {
    uuid: 'dcf097d5-a022-4f89-9ff9-cbbebaddefbf',
    firstName: 'Samuel',
    lastName: 'Reed',
    username: 'yellowmeercat793',
    avatar: 'https://randomuser.me/api/portraits/men/89.jpg',
  },
  {
    uuid: 'bcecbeaf-f546-4eed-9eb8-a1f575b1650e',
    firstName: 'Nena',
    lastName: 'Van der Ven',
    username: 'orangeostrich171',
    avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
  },
  {
    uuid: 'b79b25a3-f685-4f69-a4e2-6883005991f5',
    firstName: 'Sanja',
    lastName: 'Novaković',
    username: 'blackkoala572',
    avatar: 'https://randomuser.me/api/portraits/women/0.jpg',
  },
  {
    uuid: 'c98c2183-ad79-46b8-97b2-69ac5bdbe07b',
    firstName: 'Rebecca',
    lastName: 'Miles',
    username: 'blackgoose626',
    avatar: 'https://randomuser.me/api/portraits/women/33.jpg',
  },
  {
    uuid: 'b4596f26-acf3-4ac4-a561-71d5cfddd974',
    firstName: 'Odessa',
    lastName: 'Hartkamp',
    username: 'bigkoala196',
    avatar: 'https://randomuser.me/api/portraits/women/76.jpg',
  },
  {
    uuid: '2d325eb5-e81f-4585-b958-e79430ac0ee5',
    firstName: 'Ayan',
    lastName: 'Straume',
    username: 'orangewolf514',
    avatar: 'https://randomuser.me/api/portraits/women/22.jpg',
  },
  {
    uuid: '9bae9d48-2f92-4709-b5b8-abbd9d542118',
    firstName: 'Julian',
    lastName: 'Puente',
    username: 'sadlion683',
    avatar: 'https://randomuser.me/api/portraits/men/83.jpg',
  },
  {
    uuid: '82f5b704-4798-4391-b84d-88ee2ff519f0',
    firstName: 'Leo',
    lastName: 'Brown',
    username: 'crazysnake570',
    avatar: 'https://randomuser.me/api/portraits/men/91.jpg',
  },
  {
    uuid: '3224577f-3e95-4e29-8b8f-83c1ddb6a7c4',
    firstName: 'Gennadiy',
    lastName: 'Garan',
    username: 'orangezebra560',
    avatar: 'https://randomuser.me/api/portraits/men/6.jpg',
  },
  {
    uuid: '927be4f3-081e-4b07-ba48-a0719449a8e4',
    firstName: 'مارال',
    lastName: 'رضایی',
    username: 'lazyostrich153',
    avatar: 'https://randomuser.me/api/portraits/women/57.jpg',
  },
  {
    uuid: 'a91f4419-506b-4607-868e-ee2b1674fb72',
    firstName: 'Maria',
    lastName: 'Louis',
    username: 'tinytiger923',
    avatar: 'https://randomuser.me/api/portraits/women/6.jpg',
  },
  {
    uuid: '7aae3317-76ae-4bbc-8846-c890d0e6b184',
    firstName: 'سارا',
    lastName: 'موسوی',
    username: 'happypeacock593',
    avatar: 'https://randomuser.me/api/portraits/women/83.jpg',
  },
  {
    uuid: 'c1ec8065-1b2e-47bd-9071-e3c5ac2a2646',
    firstName: 'Shilpa',
    lastName: 'Bansal',
    username: 'bigtiger277',
    avatar: 'https://randomuser.me/api/portraits/women/62.jpg',
  },
  {
    uuid: '68dc613a-9959-4080-be3a-1c20b77ecd97',
    firstName: 'Hedwig',
    lastName: 'Langer',
    username: 'redbird790',
    avatar: 'https://randomuser.me/api/portraits/women/70.jpg',
  },
  {
    uuid: 'af0e9183-e3e7-447b-8d0c-2ae2bf35ded0',
    firstName: 'Léa',
    lastName: 'Vidal',
    username: 'silvercat690',
    avatar: 'https://randomuser.me/api/portraits/women/67.jpg',
  },
  {
    uuid: '5c3a6cba-090c-412f-ba86-544919271b14',
    firstName: 'Iina',
    lastName: 'Rautio',
    username: 'happyfrog330',
    avatar: 'https://randomuser.me/api/portraits/women/10.jpg',
  },
  {
    uuid: 'a4679d77-28a8-45c6-9e4b-c82fe5b56def',
    firstName: 'Maximilian',
    lastName: 'Durand',
    username: 'goldenpanda810',
    avatar: 'https://randomuser.me/api/portraits/men/97.jpg',
  },
  {
    uuid: 'ffba1441-6dfb-4ead-907e-2c6f88e78e71',
    firstName: 'Zacarías',
    lastName: 'Velásquez',
    username: 'heavytiger213',
    avatar: 'https://randomuser.me/api/portraits/men/95.jpg',
  },
  {
    uuid: 'c5c97f44-8ea0-4f8d-8519-20019e400059',
    firstName: 'Said',
    lastName: 'Huijnen',
    username: 'bigmeercat222',
    avatar: 'https://randomuser.me/api/portraits/men/7.jpg',
  },
  {
    uuid: 'c069b522-b549-4a1c-ba55-01ed760af80c',
    firstName: 'Sara',
    lastName: 'Christensen',
    username: 'goldenbear210',
    avatar: 'https://randomuser.me/api/portraits/women/43.jpg',
  },
  {
    uuid: '476bb4b9-b5ed-4d69-ab89-93f3e81a597b',
    firstName: 'Dylan',
    lastName: 'Lewis',
    username: 'orangemeercat637',
    avatar: 'https://randomuser.me/api/portraits/men/24.jpg',
  },
  {
    uuid: '57cb3fe6-e0f7-48e2-834b-88c8b1f5f3a2',
    firstName: 'Gabriella',
    lastName: 'Landro',
    username: 'redleopard571',
    avatar: 'https://randomuser.me/api/portraits/women/71.jpg',
  },
  {
    uuid: '25cb06be-ef01-495a-9aef-e52712d767e2',
    firstName: 'Brooke',
    lastName: 'Harper',
    username: 'yellowwolf402',
    avatar: 'https://randomuser.me/api/portraits/women/9.jpg',
  },
  {
    uuid: '2e119752-44aa-46fc-94c0-1e57a3532c2e',
    firstName: 'Bonnie',
    lastName: 'Sullivan',
    username: 'angrybird898',
    avatar: 'https://randomuser.me/api/portraits/women/65.jpg',
  },
  {
    uuid: '831cb891-8198-4eda-9268-31342f7f0060',
    firstName: 'Kristiaan',
    lastName: 'Eggens',
    username: 'happylion268',
    avatar: 'https://randomuser.me/api/portraits/men/27.jpg',
  },
  {
    uuid: '833f5cf4-9b27-4ea6-ab40-e233445a8f98',
    firstName: 'José María',
    lastName: 'Casares',
    username: 'crazybird941',
    avatar: 'https://randomuser.me/api/portraits/men/20.jpg',
  },
  {
    uuid: 'b1782937-6ee3-4456-930e-b020fd45733c',
    firstName: 'مهدیس',
    lastName: 'جعفری',
    username: 'whitepeacock141',
    avatar: 'https://randomuser.me/api/portraits/women/95.jpg',
  },
  {
    uuid: '63b4c24f-c126-4a07-8af3-ffd9b511ad88',
    firstName: 'Hans-H.',
    lastName: 'Fuhrmann',
    username: 'whiteduck946',
    avatar: 'https://randomuser.me/api/portraits/men/56.jpg',
  },
  {
    uuid: '38335a4f-b5cc-4443-b6fa-78fb2a784d50',
    firstName: 'Dennis',
    lastName: 'King',
    username: 'whiteostrich502',
    avatar: 'https://randomuser.me/api/portraits/men/69.jpg',
  },
  {
    uuid: '85a369c2-8319-4e46-8189-2c20586bd481',
    firstName: 'Leah',
    lastName: 'King',
    username: 'purpledog805',
    avatar: 'https://randomuser.me/api/portraits/women/35.jpg',
  },
  {
    uuid: '7b32cf52-4aea-4012-9d96-89945e57edf0',
    firstName: 'Joseph',
    lastName: 'Rødseth',
    username: 'purplekoala583',
    avatar: 'https://randomuser.me/api/portraits/men/25.jpg',
  },
  {
    uuid: '7d1abbbf-1566-4094-a89d-211fbb3d251c',
    firstName: 'Navami',
    lastName: 'Shayana',
    username: 'ticklishdog168',
    avatar: 'https://randomuser.me/api/portraits/women/68.jpg',
  },
  {
    uuid: '99f2283b-893c-454a-a0a1-8b13d5cc1161',
    firstName: 'Lísia',
    lastName: 'Mendes',
    username: 'biglion721',
    avatar: 'https://randomuser.me/api/portraits/women/81.jpg',
  },
  {
    uuid: 'bd096b70-0fea-4d6c-85bb-efeae2ccbed5',
    firstName: 'Myriam',
    lastName: 'Renaud',
    username: 'sadtiger972',
    avatar: 'https://randomuser.me/api/portraits/women/90.jpg',
  },
  {
    uuid: '1a639d80-3e49-40d1-a510-b4d399b2a508',
    firstName: 'Beatrice',
    lastName: 'Oliver',
    username: 'angrypeacock346',
    avatar: 'https://randomuser.me/api/portraits/women/58.jpg',
  },
  {
    uuid: '1c024c78-c398-402f-b1cb-3c7c02e92f26',
    firstName: 'Bogdan',
    lastName: 'Stanojević',
    username: 'silverpanda307',
    avatar: 'https://randomuser.me/api/portraits/men/79.jpg',
  },
  {
    uuid: '368a52fd-2749-4999-aa45-87716042cbc8',
    firstName: 'Danny',
    lastName: 'Holmes',
    username: 'goldenfrog602',
    avatar: 'https://randomuser.me/api/portraits/men/8.jpg',
  },
  {
    uuid: 'a4fc0131-9bbb-4353-bf7b-9f99241e1992',
    firstName: 'Lola',
    lastName: 'George',
    username: 'sadmeercat975',
    avatar: 'https://randomuser.me/api/portraits/women/16.jpg',
  },
  {
    uuid: '251ab919-817f-4a81-836c-e4b6912fb8f1',
    firstName: 'Cosimo',
    lastName: 'Garnier',
    username: 'happyrabbit935',
    avatar: 'https://randomuser.me/api/portraits/men/42.jpg',
  },
  {
    uuid: '8fb3d537-8c9d-463d-b516-19eb0fa39b4c',
    firstName: 'Nedeljko',
    lastName: 'Živadinović',
    username: 'bigkoala199',
    avatar: 'https://randomuser.me/api/portraits/men/74.jpg',
  },
  {
    uuid: '66ee78e8-9b62-4886-b732-4eaef4e06d6a',
    firstName: 'Emma',
    lastName: 'Harcourt',
    username: 'blackfish907',
    avatar: 'https://randomuser.me/api/portraits/women/96.jpg',
  },
  {
    uuid: '0bc610ed-f19c-47cb-88c7-fbc79b867b4c',
    firstName: 'Alexander',
    lastName: 'Pedersen',
    username: 'smallkoala999',
    avatar: 'https://randomuser.me/api/portraits/men/44.jpg',
  },
  {
    uuid: '29b8512c-462e-4386-bc6e-4f5fa607b577',
    firstName: 'Ellen',
    lastName: 'Mattila',
    username: 'tinyelephant991',
    avatar: 'https://randomuser.me/api/portraits/women/63.jpg',
  },
  {
    uuid: 'b2148a5b-141f-4921-8c7a-ddfa4f0d204f',
    firstName: 'Ole',
    lastName: 'Ebert',
    username: 'heavyostrich858',
    avatar: 'https://randomuser.me/api/portraits/men/64.jpg',
  },
  {
    uuid: '5653e5e6-5171-44a9-a484-0be421468ce8',
    firstName: 'Ginette',
    lastName: 'Gonzalez',
    username: 'crazybear736',
    avatar: 'https://randomuser.me/api/portraits/women/15.jpg',
  },
  {
    uuid: '8587b3ef-6e5c-4aad-a979-7069a1143621',
    firstName: 'Leo',
    lastName: 'Hervik',
    username: 'lazymeercat525',
    avatar: 'https://randomuser.me/api/portraits/men/62.jpg',
  },
  {
    uuid: '1c59c6c5-2497-4771-aff1-1a91f6499cad',
    firstName: 'Alexis',
    lastName: 'Scott',
    username: 'yellowcat161',
    avatar: 'https://randomuser.me/api/portraits/men/10.jpg',
  },
  {
    uuid: '689c3a7f-495f-4be5-ab06-76b3efdfaf7b',
    firstName: 'Katalina',
    lastName: 'Pejaković',
    username: 'happyladybug440',
    avatar: 'https://randomuser.me/api/portraits/women/87.jpg',
  },
  {
    uuid: '65b6375f-28ce-4dea-83ba-7f5ff2840881',
    firstName: 'Esma',
    lastName: 'Erberk',
    username: 'redladybug987',
    avatar: 'https://randomuser.me/api/portraits/women/92.jpg',
  },
  {
    uuid: '87e31a69-0a2d-4ebc-a6cf-60dcbd545ce7',
    firstName: 'Julian',
    lastName: 'Henry',
    username: 'smallzebra488',
    avatar: 'https://randomuser.me/api/portraits/men/78.jpg',
  },
  {
    uuid: 'd4bcf309-57ea-40ae-add5-acfd1393244e',
    firstName: 'Simon',
    lastName: 'Denys',
    username: 'ticklishfrog712',
    avatar: 'https://randomuser.me/api/portraits/men/85.jpg',
  },
  {
    uuid: 'ed52b2fb-6ff2-49b5-8ef6-794dece845c9',
    firstName: 'Ubiara',
    lastName: 'Rodrigues',
    username: 'happyladybug609',
    avatar: 'https://randomuser.me/api/portraits/women/87.jpg',
  },
  {
    uuid: 'afd508cc-a3c9-4c4d-8831-9c52c2f446ba',
    firstName: 'Logan',
    lastName: 'Webb',
    username: 'bluebird607',
    avatar: 'https://randomuser.me/api/portraits/men/15.jpg',
  },
  {
    uuid: 'dee957c9-e3bb-4128-9beb-583e95a00cfa',
    firstName: 'Samarth',
    lastName: 'Sullad',
    username: 'tinypanda382',
    avatar: 'https://randomuser.me/api/portraits/men/90.jpg',
  },
  {
    uuid: 'fdc23692-51d0-4d36-9f9b-bf044d2ac389',
    firstName: 'Valerio',
    lastName: 'Richard',
    username: 'organicostrich735',
    avatar: 'https://randomuser.me/api/portraits/men/30.jpg',
  },
  {
    uuid: 'cfb256a2-f1de-4846-b3ee-036fb786d252',
    firstName: 'Phoebe',
    lastName: 'Webb',
    username: 'tinybear284',
    avatar: 'https://randomuser.me/api/portraits/women/72.jpg',
  },
  {
    uuid: '41151eac-9a4d-4d47-9d58-188d3bcbe8f2',
    firstName: 'Estelle',
    lastName: 'Blanchard',
    username: 'orangebird553',
    avatar: 'https://randomuser.me/api/portraits/women/18.jpg',
  },
  {
    uuid: 'f319ce08-6199-4d02-9083-db63addd5410',
    firstName: 'Yartur',
    lastName: 'Golub',
    username: 'orangeduck851',
    avatar: 'https://randomuser.me/api/portraits/men/23.jpg',
  },
  {
    uuid: '45033c41-289e-48ec-8b48-068c625fbd81',
    firstName: 'Dubravko',
    lastName: 'Kojić',
    username: 'crazyleopard101',
    avatar: 'https://randomuser.me/api/portraits/men/75.jpg',
  },
  {
    uuid: '445a1fe3-28ec-441d-8777-df077c8175ba',
    firstName: 'Efe',
    lastName: 'Dizdar',
    username: 'heavyladybug865',
    avatar: 'https://randomuser.me/api/portraits/men/48.jpg',
  },
  {
    uuid: 'fd926ba3-017d-42a6-a50e-b24e86ec44d3',
    firstName: 'Tess',
    lastName: 'Renard',
    username: 'tinypanda240',
    avatar: 'https://randomuser.me/api/portraits/women/51.jpg',
  },
  {
    uuid: 'bed79a7c-22f9-49e9-a9e7-476a940e4d73',
    firstName: 'Morgan',
    lastName: 'Rodriguez',
    username: 'tinyladybug331',
    avatar: 'https://randomuser.me/api/portraits/men/64.jpg',
  },
  {
    uuid: 'a4f147d8-8002-4314-8731-ba37d474308d',
    firstName: 'Madeleine',
    lastName: 'Haug',
    username: 'bigpanda690',
    avatar: 'https://randomuser.me/api/portraits/women/82.jpg',
  },
  {
    uuid: 'cf9928f7-8ed6-49a8-ba64-df1493d97cc9',
    firstName: 'Regina',
    lastName: 'Watts',
    username: 'crazyswan947',
    avatar: 'https://randomuser.me/api/portraits/women/55.jpg',
  },
  {
    uuid: 'faa93e5c-3498-4084-af40-1a119419a8c1',
    firstName: 'Virgil',
    lastName: 'Peck',
    username: 'crazybear173',
    avatar: 'https://randomuser.me/api/portraits/men/8.jpg',
  },
  {
    uuid: 'f7dff26c-5f22-45da-97f3-5870d5f8136a',
    firstName: 'Victor',
    lastName: 'Madsen',
    username: 'whiteelephant415',
    avatar: 'https://randomuser.me/api/portraits/men/2.jpg',
  },
  {
    uuid: '8e228a3d-780d-4fc3-9108-3b5fb0f7545b',
    firstName: 'Kishan',
    lastName: 'Van Spronsen',
    username: 'angryleopard304',
    avatar: 'https://randomuser.me/api/portraits/men/99.jpg',
  },
  {
    uuid: 'c06ef25c-01ba-4446-943e-c073cc33b747',
    firstName: 'Renee',
    lastName: 'Armstrong',
    username: 'tinyrabbit150',
    avatar: 'https://randomuser.me/api/portraits/women/76.jpg',
  },
  {
    uuid: 'd7305287-747c-4f93-9a33-bf217198bdda',
    firstName: 'Sherry',
    lastName: 'Kim',
    username: 'brownpanda957',
    avatar: 'https://randomuser.me/api/portraits/women/43.jpg',
  },
  {
    uuid: 'acf553b1-bd67-4ea9-909f-50cf208a1d09',
    firstName: 'Martha',
    lastName: 'Rogers',
    username: 'whitefrog107',
    avatar: 'https://randomuser.me/api/portraits/women/15.jpg',
  },
  {
    uuid: '83b251ce-a251-47f5-bfd8-3fda51882b68',
    firstName: 'Luis',
    lastName: 'David',
    username: 'ticklishleopard324',
    avatar: 'https://randomuser.me/api/portraits/men/66.jpg',
  },
];
