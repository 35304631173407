import { IconLove } from 'global/lib/icons';
import { Image } from 'states/MyMedias/MyMedias';

import CardMediaMedium from '../';
import { BadgeMediaBottomMediumGap } from '../../BadgeMedia';
import CardImageHidden from '../../CardMediaHidden/CardImageHidden';

function CardImageMedium({ image }: { image: Image }) {
  return (
    <CardImageHidden image={image}>
      <CardMediaMedium media={image}>
        <BadgeMediaBottomMediumGap>
          <>
            <IconLove fontSize="small" />
            <span>{image.nbLikes}</span>
          </>
        </BadgeMediaBottomMediumGap>
      </CardMediaMedium>
    </CardImageHidden>
  );
}

export default CardImageMedium;
