import TabBookmark from './TabMedia/TabBookmark';
import TabPhoto from './TabMedia/TabPhoto';
import TabShared from './TabMedia/TabShared';
import TabVideo from './TabMedia/TabVideo';

function NavBarMedia() {
  return (
    <div className="grid grid-cols-4">
      <TabVideo />
      <TabPhoto />
      <TabShared />
      <TabBookmark />
    </div>
  );
}

export default NavBarMedia;
