import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Lang } from 'states/Settings/Settings';

import commonEn from './en/common.json';
import homeEn from './en/homePage.json';
import userPageEn from './en/userPage.json';
import commonFr from './fr/common.json';
import homeFr from './fr/homePage.json';
import userPageFr from './fr/userPage.json';

export const supportedLngs = Object.values(Lang);

const resources = {
  en: {
    home: homeEn,
    common: commonEn,
    userPage: userPageEn,
  },
  fr: {
    home: homeFr,
    common: commonFr,
    userPage: userPageFr,
  },
};

i18n.use(initReactI18next).init({
  lng: Lang.EN,
  supportedLngs,
  fallbackLng: Lang.EN,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});
