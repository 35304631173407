import { paths } from 'routes/paths';

export const isProfilePage = (path: string) => path.includes(paths.profile.index);

export const isProfileTabViews = (path: string) => path.endsWith(paths.profile.views);

export const isProfileTabLikes = (path: string) => path.endsWith(paths.profile.likes);

export const isProfileTabVideos = (path: string) =>
  path.endsWith(paths.profile.myVideos) ||
  path.endsWith(paths.profile.index) ||
  path.endsWith(paths.profile.views);

export const isProfileTabPhotos = (path: string) =>
  path.endsWith(paths.profile.myPhotos) || path.endsWith(paths.profile.likes);

export const isProfileTabShared = (path: string) => path.endsWith(paths.profile.sharedMedias);

export const isProfileTabBookmark = (path: string) => path.endsWith(paths.profile.likedMedias);

export const isHomePage = (path: string, username: string) =>
  path.endsWith(paths.home) || [paths.index, `/${username}`].includes(path);

export const isMyFollowersPage = (path: string) => path.endsWith(paths.followers.index);

export const isMyFollowingsPage = (path: string) => path.endsWith(paths.followings.index);

export const isMyFolowingMediasPage = (path: string) => path.endsWith(paths.followings.medias);

export const isPeoplePage = (path: string) => path.endsWith(paths.people);

export const isInboxPage = (path: string) => path.includes(paths.inbox);
