import { useIconStyleDefault } from 'global/hooks/useStyle';

import {
  IconFollower,
  IconFollowing,
  IconFriendsSolid,
  IconHomeSolid,
  IconInboxSolid,
  IconLove,
  IconMenu,
  IconPersonSearchSolid,
  IconProfileSolid,
  IconView,
} from './icons';

export const IconHomeDefault = () => {
  const iconColor = useIconStyleDefault();
  return <IconHomeSolid className={iconColor} />;
};

export const IconInboxDefault = () => {
  const iconColor = useIconStyleDefault();
  return <IconInboxSolid className={iconColor} />;
};

export const IconFollowingsDefault = () => {
  const iconColor = useIconStyleDefault();
  return <IconFriendsSolid className={iconColor} />;
};

export const IconProfileDefault = () => {
  const iconColor = useIconStyleDefault();
  return <IconProfileSolid className={iconColor} />;
};

export const IconPersonSearchDefault = () => {
  const iconColor = useIconStyleDefault();
  return <IconPersonSearchSolid className={iconColor} />;
};

export const IconMenuDefault = () => {
  const iconColor = useIconStyleDefault();
  return <IconMenu className={iconColor} />;
};

export const IconFollowingDefault = () => <IconFollowing />;
export const IconFollowerDefault = () => <IconFollower />;
export const IconLoveDefault = () => <IconLove />;
export const IconViewDefault = () => <IconView />;
