import { useMemo } from 'react';

import { BgColor } from 'global/enums/colors';
import { useSelector } from 'react-redux';
import { Theme } from 'states/Settings/Settings';
import { selectTheme } from 'states/Settings/settingSelectors';

export const useIconStyleDefault = () => {
  const theme = useSelector(selectTheme);
  const iconColor = useMemo(() => {
    switch (theme) {
      case Theme.LIGHT:
        return 'text-black bg-white bg-opacity-50 ring ring-2 ring-white ring-opacity-50 rounded-lg';
      case Theme.DARK:
        return 'text-white bg-black bg-opacity-50 ring ring-2 ring-black ring-opacity-50 rounded-lg';
      default:
        return 'text-gray-500 bg-white bg-opacity-50 ring ring-2 ring-white ring-opacity-50 rounded-lg';
    }
  }, [theme]);
  return iconColor;
};

export const useIconColorByBg = (bg: BgColor) => {
  const iconColor = useMemo(() => {
    switch (bg) {
      case BgColor.WHITE:
        return 'text-black';
      case BgColor.BLACK:
        return 'text-white';
      default:
        return 'text-gray-500';
    }
  }, [bg]);
  return iconColor;
};

export const useBgColorByTheme = (theme?: Theme) => {
  const actualTheme = useSelector(selectTheme);
  const bgColor = useMemo(() => {
    const themeToUse = theme || actualTheme;
    switch (themeToUse) {
      case Theme.LIGHT:
        return BgColor.BLACK;
      case Theme.DARK:
        return BgColor.WHITE;
      default:
        return BgColor.WHITE;
    }
  }, [theme, actualTheme]);
  return bgColor;
};
