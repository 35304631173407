import React, {
  useEffect,
  useState,
} from 'react';

import { TextColor } from 'global/enums/colors';
import { IconScrollToTop } from 'global/lib/icons';

function ButtonScrollToTop() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    });
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="sticky bottom-20 right-0 z-10 py-2 px-3 text-xs w-fit self-end">
      <div
        onClick={handleClick}
        className={`${visible ? '' : 'hidden'} ${
          TextColor.PRIMARY
        } bg-white bg-opacity-50 ring ring-2 ring-white ring-opacity-50 rounded-lg`}
      >
        <IconScrollToTop />
      </div>
    </div>
  );
}

export default ButtonScrollToTop;
