import axios from 'axios';
import {
  Image,
  Media,
  Video,
} from 'states/MyMedias/MyMedias';
import { SimpleUser } from 'states/User/User';
import {
  randomDate,
  randomInt,
} from 'utils/random';
import { shuffle } from 'utils/shuffle';

export const randomImageLink = () => 'https://picsum.photos/200/300?random&t=' + Math.random();

const randomPersonFromApi = async () => {
  const response = await axios.get('https://randomuser.me/api');
  const user = response.data.results[0];
  return {
    uuid: user.login.uuid,
    firstName: user.name.first,
    lastName: user.name.last,
    username: user.login.username,
    avatar: user.picture.large,
  } as SimpleUser;
};

export const getRandomImage = async (rand: number): Promise<Image> => {
  const owner = await randomPersonFromApi();
  return {
    link: `https://picsum.photos/200/300?random=${rand}`,
    nbLikes: randomInt(0, 1000),
    owner,
    dateCreated: randomDate(),
  } as Image;
};

export const getRandomImage2 = (tag?: string) => {
  const searchString = tag ? tag : 'random';
  return {
    link: `https://source.unsplash.com/random/?${searchString}/t=${randomInt(0, 1000)}`,
    nbLikes: randomInt(0, 1000),
    owner: undefined, // TODO
    dateCreated: randomDate(),
  } as Image;
};

export const getImages = async (limit: number): Promise<Image[]> => {
  const images: Image[] = [];
  for (let i = 0; i < limit; i++) {
    const image = await getRandomImage(i);
    images.push(image);
  }
  console.log('images', images);

  return images;
};

const giphy = {
  baseURL: 'https://api.giphy.com/v1/gifs/',
  apiKey: '0UTRbFtkMxAplrohufYco5IY74U8hOes',
  // apiKey: 'yN3HoNm8ex0bUFolOM3tiUqb4uwh86yL',
  tag: 'fail',
  type: 'random',
  rating: 'pg-13',
};

export const randomGifLink = encodeURI(
  `${giphy.baseURL}${giphy.type}?api_key=${giphy.apiKey}&tag=${giphy.tag}&rating=${giphy.rating}`
);

export const getRandomVideo = async () => {
  const res = await axios.get(randomGifLink);
  const owner = await randomPersonFromApi();
  return {
    link: res.data.data.images.original.url,
    nbViews: randomInt(0, 1000),
    owner, // TODO
    dateCreated: randomDate(),
  } as Video;
};

export const randomGifLink2 = encodeURI(
  `https://g.tenor.com/v1/random?q=rqndom&limit=1&key=LIVDSRZULELA`
);

export const getRandomVideos = async (limit: number, tag?: string): Promise<Video[]> => {
  const searchString = tag ? tag : 'random';
  const res = await axios.get(
    `https://g.tenor.com/v1/random?q=${searchString}&limit=${limit}&key=LIVDSRZULELA`
  );
  const owners: SimpleUser[] = [];
  for (let i = 0; i < res.data.results.length; i++) {
    owners.push(await randomPersonFromApi());
  }

  const videos = res.data.results.map((result: any, index: number) => {
    return {
      link: result.media[0].gif.url,
      nbViews: randomInt(0, 1000),
      owner: owners[index], // TODO
      dateCreated: randomDate(),
    } as Video;
  });

  console.log('videos', videos);

  return videos;
};

export const getRandomMedias = async (limit: number, tag?: string): Promise<Media[]> => {
  const images = await getImages(Math.round(limit / 2));
  const videos = await getRandomVideos(limit - Math.round(limit / 2), tag);
  const medias = shuffle([...images, ...videos]);
  console.log('medias', medias);
  return medias;
};
