import { followersUsers } from 'global/constants/data-test/followersUsers';
import { followingsUsers } from 'global/constants/data-test/followingsUsers';
import { publicUsers } from 'global/constants/data-test/publicUsers';
import { SimpleUser } from 'states/User/User';

import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { People } from './People';

const initialState: People = {
  publicUsers: publicUsers,
  followingsUsers: followingsUsers,
  followersUsers: followersUsers,
};

export const peopleSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    addPublicUsers: (state: People, action: PayloadAction<SimpleUser[]>) => {
      state.publicUsers.push(...action.payload);
    },
    addFollowingsUsers: (state: People, action: PayloadAction<SimpleUser[]>) => {
      state.followingsUsers.push(...action.payload);
    },
    addFollowersUsers: (state: People, action: PayloadAction<SimpleUser[]>) => {
      state.followersUsers.push(...action.payload);
    },
  },
});

export const { addPublicUsers, addFollowingsUsers, addFollowersUsers } = peopleSlice.actions;
export default peopleSlice.reducer;
