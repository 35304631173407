import { validUser } from 'pages/public/PageLogin/FormLogin';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Outlet,
} from 'react-router-dom';
import { paths } from 'routes/paths';
import { User } from 'states/User/User';
import { selectUser } from 'states/User/userSelectors';

interface Props {
  user?: User;
}

function RoutePrivate() {
  const user = useSelector(selectUser);
  const isLogin =
    user &&
    user.username &&
    user.username === validUser.username &&
    user.password &&
    user.password === validUser.password;

  return isLogin ? <Outlet /> : <Navigate to={`${paths.login}`} />;
}

export default RoutePrivate;
