import { useMemo } from 'react';

import { TextColor } from 'global/enums/colors';
import useLink from 'global/hooks/usePrivateLink';
import { IconView } from 'global/lib/icons';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { paths } from 'routes/paths';
import { selectUser } from 'states/User/userSelectors';

import BarItem from '../';

function NbViews() {
  const user = useSelector(selectUser);
  const link = useLink(paths.profile.views);
  const location = useLocation();

  const textColor = useMemo(
    () => (location.pathname.endsWith(paths.profile.views) ? TextColor.PRIMARY : ''),
    [location]
  );

  return (
    <BarItem linkTo={link} badge={user.socialStatistics.nbViews} textColor={textColor}>
      <IconView />
    </BarItem>
  );
}

export default NbViews;
