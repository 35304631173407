import { BadgePosition } from 'global/enums';

interface Props {
  position: BadgePosition;
  children?: JSX.Element;
}

export default function BadgeMedia({ position, children }: Props) {
  return (
    <div className={`absolute flex ${position} gap-1 text-white text-xs items-center opacity-70`}>
      {children}
    </div>
  );
}

export const BadgeMediaTopSmallGap = ({ children }: { children?: JSX.Element }) => {
  return <BadgeMedia position={BadgePosition.TOP_SMALL_GAP}>{children}</BadgeMedia>;
};

export const BadgeMediaTopMediumGap = ({ children }: { children?: JSX.Element }) => {
  return <BadgeMedia position={BadgePosition.TOP_MEDIUM_GAP}>{children}</BadgeMedia>;
};

export const BadgeMediaTopLargeGap = ({ children }: { children?: JSX.Element }) => {
  return <BadgeMedia position={BadgePosition.TOP_LARGE_GAP}>{children}</BadgeMedia>;
};

export const BadgeMediaTopFullScreenGap = ({ children }: { children?: JSX.Element }) => {
  return <BadgeMedia position={BadgePosition.TOP_FULLSCREEN_GAP}>{children}</BadgeMedia>;
};

export const BadgeMediaBottomSmallGap = ({ children }: { children?: JSX.Element }) => {
  return <BadgeMedia position={BadgePosition.BOTTOM_SMALL_GAP}>{children}</BadgeMedia>;
};

export const BadgeMediaBottomMediumGap = ({ children }: { children?: JSX.Element }) => {
  return <BadgeMedia position={BadgePosition.BOTTOM_MEDIUM_GAP}>{children}</BadgeMedia>;
};

export const BadgeMediaBottomLargeGap = ({ children }: { children?: JSX.Element }) => {
  return <BadgeMedia position={BadgePosition.BOTTOM_LARGE_GAP}>{children}</BadgeMedia>;
};

export const BadgeMediaBottomFullScreenGap = ({ children }: { children?: JSX.Element }) => {
  return <BadgeMedia position={BadgePosition.BOTTOM_FULLSCREEN_GAP}>{children}</BadgeMedia>;
};
