import axios from 'axios';
import {
  SimpleUser,
  User,
  UserWithMessages,
} from 'states/User/User';

import { followingsMessages } from './followingsMessages';
import { randomImageLink } from './medias';
import { randomMessageFromList } from './messages';

export const randomPersonFromApi = async () => {
  const response = await axios.get('https://randomuser.me/api');
  const user = response.data.results[0];
  return {
    uuid: user.login.uuid,
    firstName: user.name.first,
    lastName: user.name.last,
    username: user.login.username,
    avatar: user.picture.large,
  } as SimpleUser;
};

export const user: User = {
  uuid: 'uuid',
  firstName: 'Light',
  lastName: 'Pham',
  username: 'lightpham',
  password: '',
  avatar: randomImageLink(),
  email: '',
  role: '',
  socialStatistics: {
    nbFollowers: 100,
    nbFollowings: 50,
    nbPosts: 100,
    nbComments: 200,
    nbLikes: 100000,
    nbViews: 50000,
    nbPhotos: 100,
    nbVideos: 50,
    nbShared: 50,
    nbBookmarks: 100,
  },
  description: 'www.lightdevops.com',
};

export const getPeople = async (length: number) => {
  const people: SimpleUser[] = [];
  for (let i = 0; i < length; i++) {
    const person = await randomPersonFromApi();
    people.push(person);
  }
  console.log('people', people);

  return people;
};

export const randomPersonFromList = (people: SimpleUser[]) => {
  return people[Math.floor(Math.random() * people.length)];
};

export const fetchPeopleWithMessages = async (people: SimpleUser[]) => {
  const peopleWithMessages: UserWithMessages[] = [];
  // const messages = await fetchRandomMessagesFromApi();
  people.forEach((person) => {
    peopleWithMessages.push({
      ...person,
      lastMessage: randomMessageFromList(followingsMessages),
      messages: [],
    });
  });
  return peopleWithMessages;
};
