import React from 'react';

import { Video } from 'states/MyMedias/MyMedias';

import CardMediaHidden from '../';
import CardVideoFullScreen from '../../CardMediaFullScreen/CardVideoFullScreen';

function CardVideoHidden({ video, children }: { video: Video; children: JSX.Element }) {
  return (
    <CardMediaHidden hiddenComponent={<CardVideoFullScreen video={video} />} children={children} />
  );
}

export default CardVideoHidden;
