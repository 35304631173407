import {
  KeyboardEvent,
  useCallback,
} from 'react';

import {
  IconMicTertiary,
  IconSendTertiary,
} from 'global/lib/IconsTertiary';
import {
  SubmitHandler,
  useForm,
} from 'react-hook-form';

interface Inputs {
  message: string;
}

function InputMessage() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = useCallback((data) => console.log(data), []);

  const handleKeyUp = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSubmit(onSubmit);
      }
    },
    [handleSubmit, onSubmit]
  );
  return (
    <form
      className="sticky bottom-12 left-0 z-20 flex items-center py-2"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex w-full pl-5 pr-1 py-2 justify-between bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <input
          type="text"
          className="w-full"
          placeholder="Type a message"
          {...register('message', { required: true })}
          onKeyUp={handleKeyUp}
        />
        <div className="flex">
          <button type="button" className="flex items-center pr-3">
            <IconMicTertiary />
          </button>
          <button type="button" className="flex items-center">
            <IconSendTertiary />
          </button>
        </div>
      </div>
    </form>
  );
}

export default InputMessage;
