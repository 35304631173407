import { useMemo } from 'react';

import { AvatarMedium } from 'global/components/Avatar';
import { useSelector } from 'react-redux';
import { selectFollowingsUsers } from 'states/People/peopleSelectors';
import { SimpleUser } from 'states/User/User';
import { cutString } from 'utils/formatters';

import ButtonFollow from './ButtonFollow';
import ButtonUnFollow from './ButtonUnFollow';

function RowPerson({ person }: { person: SimpleUser }) {
  const followings = useSelector(selectFollowingsUsers);
  const isFollowing = useMemo(() => followings.includes(person), []);

  return (
    <div className="grid grid-cols-12 gap-6 items-center">
      <div className="col-span-2 flex justify-end">
        <AvatarMedium src={person.avatar} />
      </div>
      <div className="col-span-7 flex flex-col">
        <div>{cutString(`${person.firstName} ${person.lastName}`, 15)}</div>
        <div>{cutString(person.username, 15)}</div>
      </div>

      <div className="col-span-3">{isFollowing ? <ButtonUnFollow /> : <ButtonFollow />}</div>
    </div>
  );
}

export default RowPerson;
