import { myBookmarkedMedias } from 'global/constants/data-test/myLikedMedias';
import { myPhotos } from 'global/constants/data-test/myPhotos';
import { mySharedMedias } from 'global/constants/data-test/mySharedMedias';
import { myVideos } from 'global/constants/data-test/myVideos';

import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  Image,
  Media,
  MyMedias,
  Video,
} from './MyMedias';

const initialState: MyMedias = {
  myPhotos: myPhotos,
  myVideos: myVideos,
  sharedMedias: mySharedMedias,
  bookmarkedMedias: myBookmarkedMedias,
};

export const myMediasSlice = createSlice({
  name: 'myMedias',
  initialState,
  reducers: {
    addMyPhotos: (state: MyMedias, action: PayloadAction<Image[]>) => {
      state.myPhotos.push(...action.payload);
    },
    addMyVideos: (state: MyMedias, action: PayloadAction<Video[]>) => {
      state.myVideos.push(...action.payload);
    },
    addSharedMedias: (state: MyMedias, action: PayloadAction<Media[]>) => {
      state.sharedMedias.push(...action.payload);
    },
    addBookmarkedMedias: (state: MyMedias, action: PayloadAction<Media[]>) => {
      state.bookmarkedMedias.push(...action.payload);
    },
  },
});

export const { addMyPhotos, addMyVideos, addSharedMedias, addBookmarkedMedias } =
  myMediasSlice.actions;
export default myMediasSlice.reducer;
