import LayoutPrimary from 'layouts/LayoutPrimary';
import { GridMediaLL } from 'pages/components/GridMedia';
import { getFollowingsMediasOrderByDate } from 'services/mediaServices';

function PageFollowingsMedias() {
  return (
    <LayoutPrimary>
      <GridMediaLL fetchData={getFollowingsMediasOrderByDate} />
    </LayoutPrimary>
  );
}

export default PageFollowingsMedias;
