import {
  useCallback,
  useMemo,
} from 'react';

import { TextColor } from 'global/enums/colors';
import useLink from 'global/hooks/usePrivateLink';
import { IconBookmark } from 'global/lib/icons';
import { IconBookmarksSolidPrimary } from 'global/lib/IconsPrimary';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { paths } from 'routes/paths';
import { selectUser } from 'states/User/userSelectors';
import { isProfileTabBookmark } from 'utils/pathCheck';

import TabMedia from '../';

function TabBookmark() {
  const location = useLocation();
  const link = useLink(paths.profile.likedMedias);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tabIcon: any = useMemo(
    () =>
      isProfileTabBookmark(location.pathname) ? <IconBookmarksSolidPrimary /> : <IconBookmark />,
    [location]
  );

  const badgeColor = useMemo(
    () => (isProfileTabBookmark(location.pathname) ? TextColor.PRIMARY : undefined),
    [location]
  );

  const onClick = useCallback(() => {
    navigate(link);
  }, [link]);

  return (
    <TabMedia onClick={onClick} badge={user.socialStatistics.nbBookmarks} badgeColor={badgeColor}>
      {tabIcon}
    </TabMedia>
  );
}

export default TabBookmark;
