import PageFollowersList from 'pages/private/PageFollowersList';
import PageFollowingsList from 'pages/private/PageFollowingsList';
import PageFollowingsMedias from 'pages/private/PageFollowingsMedias';
import PageInboxSummary from 'pages/private/PageInboxSummary';
import PageMessages from 'pages/private/PageMessages';
import PagePeople from 'pages/private/PagePeople';
import PageProfile from 'pages/private/PageProfile';
import PageSettings from 'pages/private/PageSettings';
import PageHome from 'pages/public/PageHome';
import PageLogin from 'pages/public/PageLogin';
import { useSelector } from 'react-redux';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import { selectUser } from 'states/User/userSelectors';

import { paths } from './paths';
import RoutePrivate from './RoutePrivate';

function App() {
  const user = useSelector(selectUser);

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path={`${paths.index}`} element={<PageHome />} />
        <Route path={`${paths.login}`} element={<PageLogin />} />

        <Route element={<RoutePrivate />}>
          <Route path={`${user.username}${paths.home}`} element={<PageHome />} />
          <Route path={`${user.username}${paths.people}`} element={<PagePeople />} />
          <Route path={`${user.username}${paths.inbox}`} element={<PageInboxSummary />} />
          <Route path={`${user.username}${paths.inbox}/:toUsername`} element={<PageMessages />} />
          <Route path={`${user.username}${paths.profile.index}/*`} element={<PageProfile />} />
          <Route
            path={`${user.username}${paths.followings.index}`}
            element={<PageFollowingsList />}
          />
          <Route
            path={`${user.username}${paths.followings.medias}`}
            element={<PageFollowingsMedias />}
          />
          <Route
            path={`${user.username}${paths.followers.index}`}
            element={<PageFollowersList />}
          />
          <Route path={`${user.username}${paths.settings.index}`} element={<PageSettings />} />
        </Route>

        <Route path="*" element={<PageHome />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
