import { SimpleUser } from 'states/User/User';

export const followersUsers: SimpleUser[] = [
  {
    uuid: 'e90fed6d-aff7-4b6a-8252-b468772b565e',
    firstName: 'Teresa',
    lastName: 'Ocampo',
    username: 'bluepeacock328',
    avatar: 'https://randomuser.me/api/portraits/women/47.jpg',
  },
  {
    uuid: 'acaa8b6a-0422-4f30-97d5-9ab5b33a8995',
    firstName: 'Nawin',
    lastName: 'Van den Ouweland',
    username: 'ticklishfrog631',
    avatar: 'https://randomuser.me/api/portraits/men/36.jpg',
  },
  {
    uuid: '34c03532-c860-4e9d-8a1c-c7a2c762fea4',
    firstName: 'Coşkun',
    lastName: 'Dizdar',
    username: 'bigpeacock797',
    avatar: 'https://randomuser.me/api/portraits/men/18.jpg',
  },
  {
    uuid: 'eb7259e2-308f-4592-b7b5-923e09046e14',
    firstName: 'Sophie',
    lastName: 'Muller',
    username: 'greenmouse249',
    avatar: 'https://randomuser.me/api/portraits/women/4.jpg',
  },
  {
    uuid: '395695f4-2bec-44f6-9e16-97339c778fb1',
    firstName: 'Caitlin',
    lastName: 'Roberts',
    username: 'greenmeercat517',
    avatar: 'https://randomuser.me/api/portraits/women/60.jpg',
  },
  {
    uuid: 'be12c489-e181-4381-afde-2262d91d1fcf',
    firstName: 'Oskari',
    lastName: 'Toro',
    username: 'ticklishtiger257',
    avatar: 'https://randomuser.me/api/portraits/men/16.jpg',
  },
  {
    uuid: '8ae50a7b-81f4-404b-8ef6-0d3c8d5505a6',
    firstName: 'Leo',
    lastName: 'Miller',
    username: 'whitegorilla103',
    avatar: 'https://randomuser.me/api/portraits/men/81.jpg',
  },
  {
    uuid: '327a69d8-1ba0-4292-80d5-8e9f2c7da833',
    firstName: 'Dana',
    lastName: 'Voycehivska',
    username: 'greenlion922',
    avatar: 'https://randomuser.me/api/portraits/women/12.jpg',
  },
  {
    uuid: '3c1cb833-1e4f-4569-8722-95211ddab570',
    firstName: 'Chakradev',
    lastName: 'Saniel',
    username: 'bigdog658',
    avatar: 'https://randomuser.me/api/portraits/men/9.jpg',
  },
  {
    uuid: 'adc47f33-9942-4f94-9939-673b17c97b9d',
    firstName: 'Lisa',
    lastName: 'Sims',
    username: 'organicgorilla515',
    avatar: 'https://randomuser.me/api/portraits/women/7.jpg',
  },
  {
    uuid: '8652f2ea-bd98-4a53-9c17-38747ab8ab69',
    firstName: 'سینا',
    lastName: 'صدر',
    username: 'beautifulkoala313',
    avatar: 'https://randomuser.me/api/portraits/men/26.jpg',
  },
  {
    uuid: '22f37fed-4ed6-4fd0-b245-2119801f385b',
    firstName: 'Clifford',
    lastName: 'Clark',
    username: 'goldenfrog229',
    avatar: 'https://randomuser.me/api/portraits/men/58.jpg',
  },
  {
    uuid: 'd8f5ba11-612d-45e6-a556-86904e78eeef',
    firstName: 'Megan',
    lastName: 'Lavoie',
    username: 'happyleopard492',
    avatar: 'https://randomuser.me/api/portraits/women/28.jpg',
  },
  {
    uuid: 'a13ea714-0f45-47ea-85a8-e10bd1724791',
    firstName: 'Minttu',
    lastName: 'Hakola',
    username: 'orangeswan183',
    avatar: 'https://randomuser.me/api/portraits/women/79.jpg',
  },
  {
    uuid: '4b3475a8-5fea-4cba-b2c5-6e506ac50506',
    firstName: 'Evangelia',
    lastName: 'Reil',
    username: 'greengorilla899',
    avatar: 'https://randomuser.me/api/portraits/women/40.jpg',
  },
  {
    uuid: '5e9f92f1-2f31-42fe-ab9a-7b0751715f48',
    firstName: 'François',
    lastName: 'Rolland',
    username: 'organicostrich523',
    avatar: 'https://randomuser.me/api/portraits/men/48.jpg',
  },
  {
    uuid: 'f085149e-0bd9-4cb0-99f1-52e38bac5e17',
    firstName: 'Wenzel',
    lastName: 'Bellmann',
    username: 'yellowbutterfly423',
    avatar: 'https://randomuser.me/api/portraits/men/20.jpg',
  },
  {
    uuid: 'd0b985a5-a214-4d4d-8ef7-174516adf1cc',
    firstName: 'Romana',
    lastName: 'Aubert',
    username: 'lazybutterfly521',
    avatar: 'https://randomuser.me/api/portraits/women/12.jpg',
  },
  {
    uuid: '3f0b8679-54ff-4dca-ab07-0248d5c31426',
    firstName: 'Cesar',
    lastName: 'Vargas',
    username: 'angryladybug121',
    avatar: 'https://randomuser.me/api/portraits/men/51.jpg',
  },
  {
    uuid: '79e8afe9-516d-4d80-b4b8-ea7eefd465f4',
    firstName: 'Johanna',
    lastName: 'Myrland',
    username: 'lazytiger716',
    avatar: 'https://randomuser.me/api/portraits/women/87.jpg',
  },
  {
    uuid: '3c884002-c729-485a-917d-8e3d4b65cec2',
    firstName: 'David',
    lastName: 'Ross',
    username: 'heavyswan554',
    avatar: 'https://randomuser.me/api/portraits/men/83.jpg',
  },
  {
    uuid: '1595cc69-a4a2-40ce-9ed5-639f0d3d663f',
    firstName: 'آوین',
    lastName: 'سلطانی نژاد',
    username: 'greengorilla309',
    avatar: 'https://randomuser.me/api/portraits/women/5.jpg',
  },
  {
    uuid: '4580d01a-0a97-4065-b6f8-4672e90375b5',
    firstName: 'Franz Josef',
    lastName: 'Herrmann',
    username: 'yellowleopard348',
    avatar: 'https://randomuser.me/api/portraits/men/16.jpg',
  },
  {
    uuid: 'e2f50bfd-7f85-42b3-8fb6-5d1d72e8ab55',
    firstName: 'Stephanie',
    lastName: 'Carter',
    username: 'happyfrog886',
    avatar: 'https://randomuser.me/api/portraits/women/43.jpg',
  },
  {
    uuid: '9821378e-8b41-41e8-8c84-fe8688189233',
    firstName: 'Magali',
    lastName: 'Philippi',
    username: 'crazyzebra934',
    avatar: 'https://randomuser.me/api/portraits/women/49.jpg',
  },
  {
    uuid: 'bd965472-4b0e-4147-a008-5d4504e8b6af',
    firstName: 'Neotero',
    lastName: 'Ribeiro',
    username: 'beautifullion382',
    avatar: 'https://randomuser.me/api/portraits/men/33.jpg',
  },
  {
    uuid: '91a50974-68dd-483b-9ea1-2ed3dd0615e2',
    firstName: 'Kayla',
    lastName: 'Bowman',
    username: 'bigcat941',
    avatar: 'https://randomuser.me/api/portraits/women/83.jpg',
  },
  {
    uuid: '47c7f58b-4ae8-42f0-b5e5-8b454ceda8d8',
    firstName: 'Camille',
    lastName: 'Kowalski',
    username: 'goldenlion737',
    avatar: 'https://randomuser.me/api/portraits/women/29.jpg',
  },
  {
    uuid: '4fd0c863-379f-4562-86b0-93978cec843d',
    firstName: 'Even',
    lastName: 'Aaen',
    username: 'sadbird617',
    avatar: 'https://randomuser.me/api/portraits/men/44.jpg',
  },
  {
    uuid: '4381accb-78b7-4d99-aab4-96dc9bb41f40',
    firstName: 'Deborah',
    lastName: 'Fields',
    username: 'crazymeercat452',
    avatar: 'https://randomuser.me/api/portraits/women/54.jpg',
  },
  {
    uuid: 'e9ec4b1e-71d0-4afc-a713-7478dd16d3c7',
    firstName: 'Taylor',
    lastName: 'Meyer',
    username: 'brownkoala340',
    avatar: 'https://randomuser.me/api/portraits/women/82.jpg',
  },
  {
    uuid: '74a8aa39-9c0b-40b1-a486-8b63553e4579',
    firstName: 'Jen',
    lastName: 'Ford',
    username: 'happylion756',
    avatar: 'https://randomuser.me/api/portraits/women/30.jpg',
  },
  {
    uuid: '5eeca144-2f04-4fbd-9ac9-79c68aa44dca',
    firstName: 'Henning',
    lastName: 'Ullestad',
    username: 'silvermeercat548',
    avatar: 'https://randomuser.me/api/portraits/men/41.jpg',
  },
  {
    uuid: 'fd338d35-fe81-4b5d-9cf2-ca2087bcf5e2',
    firstName: 'Zacarías',
    lastName: 'Salgado',
    username: 'yellowcat799',
    avatar: 'https://randomuser.me/api/portraits/men/0.jpg',
  },
  {
    uuid: 'c509bc95-90de-4842-84a9-667854b446fa',
    firstName: 'Samuel',
    lastName: 'Heia',
    username: 'redmeercat490',
    avatar: 'https://randomuser.me/api/portraits/men/51.jpg',
  },
  {
    uuid: '0a80b435-26cf-46b6-a283-557ecf87df21',
    firstName: 'Aiden',
    lastName: 'Miller',
    username: 'orangeduck429',
    avatar: 'https://randomuser.me/api/portraits/men/93.jpg',
  },
  {
    uuid: '697a41a6-d773-4a14-8cc2-2701b5af4455',
    firstName: 'Jeppe',
    lastName: 'Nielsen',
    username: 'silverdog241',
    avatar: 'https://randomuser.me/api/portraits/men/12.jpg',
  },
  {
    uuid: '4d8034bf-2f1d-4d38-a191-8a08b43d1d4c',
    firstName: 'Elsbeth',
    lastName: 'Pierre',
    username: 'beautifulleopard714',
    avatar: 'https://randomuser.me/api/portraits/women/69.jpg',
  },
  {
    uuid: 'e62fc8b1-06b7-4184-96b3-67c250de9890',
    firstName: 'Susanna',
    lastName: 'Hoffman',
    username: 'bluegoose532',
    avatar: 'https://randomuser.me/api/portraits/women/31.jpg',
  },
  {
    uuid: 'a2038bef-cb35-4170-a18e-3f57c09beda5',
    firstName: 'Melinda',
    lastName: 'Cardoso',
    username: 'tinyduck944',
    avatar: 'https://randomuser.me/api/portraits/women/78.jpg',
  },
  {
    uuid: 'edd7901a-410b-4899-911a-932555ec6f29',
    firstName: 'Ülkü',
    lastName: 'Yazıcı',
    username: 'purplemeercat286',
    avatar: 'https://randomuser.me/api/portraits/women/35.jpg',
  },
  {
    uuid: '9e3ffbd2-a533-46ec-a4cf-3c2a50e67d8f',
    firstName: 'Lauren',
    lastName: 'Vasquez',
    username: 'whiteelephant828',
    avatar: 'https://randomuser.me/api/portraits/women/31.jpg',
  },
  {
    uuid: 'e2042365-c61c-4dd2-b2ae-83b4a991da72',
    firstName: 'Tvorilad',
    lastName: 'Gatcuk',
    username: 'purpletiger565',
    avatar: 'https://randomuser.me/api/portraits/men/29.jpg',
  },
  {
    uuid: '1a4a90de-967b-4e09-8509-892f2c755d18',
    firstName: 'Bastien',
    lastName: 'Martin',
    username: 'angrylion591',
    avatar: 'https://randomuser.me/api/portraits/men/94.jpg',
  },
  {
    uuid: '6ede7a34-177c-44c8-b788-005475767232',
    firstName: 'Ella',
    lastName: 'Taylor',
    username: 'orangebird115',
    avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
  },
  {
    uuid: '6488bab6-86c3-425a-908f-8a5f095b2a3f',
    firstName: 'Mishka',
    lastName: 'Fernandes',
    username: 'yellowleopard706',
    avatar: 'https://randomuser.me/api/portraits/women/3.jpg',
  },
  {
    uuid: '312cfaa1-66a7-44f6-9ec6-dac0ca49ea02',
    firstName: 'Danilo',
    lastName: 'Boyer',
    username: 'smallgorilla876',
    avatar: 'https://randomuser.me/api/portraits/men/0.jpg',
  },
  {
    uuid: '51a4a8ca-e341-4687-9852-96f36d25280d',
    firstName: 'Ercílio',
    lastName: 'Campos',
    username: 'greendog654',
    avatar: 'https://randomuser.me/api/portraits/men/22.jpg',
  },
  {
    uuid: '8a606e3b-c05f-41b7-993b-99faed9ee74a',
    firstName: 'Lyam',
    lastName: 'Masson',
    username: 'bluepanda651',
    avatar: 'https://randomuser.me/api/portraits/men/64.jpg',
  },
  {
    uuid: '167e0935-ec26-4615-b9f8-d59b74e442a2',
    firstName: 'Billie',
    lastName: 'Henry',
    username: 'lazysnake398',
    avatar: 'https://randomuser.me/api/portraits/women/47.jpg',
  },
  {
    uuid: '0af457a3-253b-4527-8b38-a0e175f130b5',
    firstName: 'Aapo',
    lastName: 'Nurmi',
    username: 'blueleopard680',
    avatar: 'https://randomuser.me/api/portraits/men/11.jpg',
  },
  {
    uuid: 'b63da83a-1ff5-48a1-b12f-9629fd381e92',
    firstName: 'Marine',
    lastName: 'Giraud',
    username: 'whitelion467',
    avatar: 'https://randomuser.me/api/portraits/women/92.jpg',
  },
  {
    uuid: 'aab0c45d-3c91-4bcc-a4bd-753820fedc00',
    firstName: 'كيان',
    lastName: 'مرادی',
    username: 'sadswan508',
    avatar: 'https://randomuser.me/api/portraits/men/53.jpg',
  },
  {
    uuid: '6ce7f4e1-4802-4b5e-a9ae-67d808454bca',
    firstName: 'Gamaliel',
    lastName: 'Pinto',
    username: 'redtiger447',
    avatar: 'https://randomuser.me/api/portraits/men/56.jpg',
  },
  {
    uuid: '54601a9f-d502-4bc7-aac7-d5292d86ce2d',
    firstName: 'Franklin',
    lastName: 'Gibson',
    username: 'smallladybug848',
    avatar: 'https://randomuser.me/api/portraits/men/59.jpg',
  },
  {
    uuid: '43b19e62-87d4-4115-a802-dc3594bd633b',
    firstName: 'Ira',
    lastName: 'Chiplunkar',
    username: 'bluesnake812',
    avatar: 'https://randomuser.me/api/portraits/women/56.jpg',
  },
  {
    uuid: 'a3fd48cd-36ae-4cd0-84ad-302b90e3a7fd',
    firstName: 'Laurie',
    lastName: 'Claire',
    username: 'ticklishduck110',
    avatar: 'https://randomuser.me/api/portraits/women/55.jpg',
  },
  {
    uuid: 'def1c5da-df79-46ef-a63c-76aea8f8700a',
    firstName: 'Rosenira',
    lastName: 'Silva',
    username: 'silverladybug458',
    avatar: 'https://randomuser.me/api/portraits/women/62.jpg',
  },
  {
    uuid: '3127969d-071e-4a6c-84f7-fdfa459bf7ff',
    firstName: 'Ehrhard',
    lastName: 'Böhnke',
    username: 'whiteladybug963',
    avatar: 'https://randomuser.me/api/portraits/men/63.jpg',
  },
  {
    uuid: 'dbf7cb86-ec56-45f2-9bfe-b5e0b5303c0a',
    firstName: 'Steijn',
    lastName: 'Van Bodegom',
    username: 'yellowswan730',
    avatar: 'https://randomuser.me/api/portraits/men/5.jpg',
  },
  {
    uuid: '6d5e1761-9a0d-48a3-800d-085283af32cf',
    firstName: 'Esat',
    lastName: 'Yazıcı',
    username: 'angryswan126',
    avatar: 'https://randomuser.me/api/portraits/men/12.jpg',
  },
  {
    uuid: '773a1b7c-d76f-47ed-a9e7-3a6d56c6392e',
    firstName: 'Irma',
    lastName: 'Lomeli',
    username: 'lazyelephant663',
    avatar: 'https://randomuser.me/api/portraits/women/36.jpg',
  },
  {
    uuid: 'bcf2178e-7849-415b-afee-e1ffdb70cc8d',
    firstName: 'Srđan',
    lastName: 'Stevanović',
    username: 'tinytiger905',
    avatar: 'https://randomuser.me/api/portraits/men/97.jpg',
  },
  {
    uuid: 'f5daf057-cb58-4951-8831-32a687f346aa',
    firstName: 'Teresa',
    lastName: 'Hall',
    username: 'bigduck243',
    avatar: 'https://randomuser.me/api/portraits/women/53.jpg',
  },
  {
    uuid: '26b24013-aa01-4b8d-a5f5-33d1aebb0e0d',
    firstName: 'Barış',
    lastName: 'Ertürk',
    username: 'whitegoose275',
    avatar: 'https://randomuser.me/api/portraits/men/10.jpg',
  },
  {
    uuid: '753868c9-58fc-4a2b-b4bc-3569f42d5a0b',
    firstName: 'Joaquín',
    lastName: 'Figueroa',
    username: 'sadrabbit319',
    avatar: 'https://randomuser.me/api/portraits/men/62.jpg',
  },
  {
    uuid: '301ddbe9-7b58-43e6-b2be-7e857713bde5',
    firstName: 'Garnoslav',
    lastName: 'Bebeshko',
    username: 'sadswan532',
    avatar: 'https://randomuser.me/api/portraits/men/33.jpg',
  },
  {
    uuid: 'da288ca5-b7b2-4ff6-b116-e805df944691',
    firstName: 'Pauline',
    lastName: 'Martin',
    username: 'heavytiger576',
    avatar: 'https://randomuser.me/api/portraits/women/80.jpg',
  },
  {
    uuid: '1b01ef87-42c8-4c46-b6de-f088cd19f11b',
    firstName: 'Arianna',
    lastName: 'Peters',
    username: 'whitewolf109',
    avatar: 'https://randomuser.me/api/portraits/women/72.jpg',
  },
  {
    uuid: 'd43fbefb-f9a1-4c6e-9df8-b52ef92ccc44',
    firstName: 'Rafael',
    lastName: 'Fernández',
    username: 'yellowleopard744',
    avatar: 'https://randomuser.me/api/portraits/men/91.jpg',
  },
  {
    uuid: '4a69066b-03a1-4eee-be67-6e5dbeb7cbc6',
    firstName: 'Enni',
    lastName: 'Nikula',
    username: 'lazygorilla941',
    avatar: 'https://randomuser.me/api/portraits/women/72.jpg',
  },
  {
    uuid: '2e47e9fd-e5df-4f6e-8262-d2e1e17f9af1',
    firstName: 'Bertram',
    lastName: 'Rasmussen',
    username: 'greendog832',
    avatar: 'https://randomuser.me/api/portraits/men/10.jpg',
  },
  {
    uuid: '9d07db96-accd-4eae-acf7-111bd530b8f1',
    firstName: 'Latife',
    lastName: 'Uluhan',
    username: 'lazybutterfly187',
    avatar: 'https://randomuser.me/api/portraits/women/79.jpg',
  },
  {
    uuid: 'ce6c83e2-2b45-45f6-8525-9a1e429d821a',
    firstName: 'Purificación',
    lastName: 'Serrano',
    username: 'goldenmeercat718',
    avatar: 'https://randomuser.me/api/portraits/women/25.jpg',
  },
  {
    uuid: '1d968233-c695-4978-8b58-db3f40f27f68',
    firstName: 'Benno',
    lastName: 'Seidler',
    username: 'goldenleopard886',
    avatar: 'https://randomuser.me/api/portraits/men/97.jpg',
  },
  {
    uuid: '45325953-c84c-43d9-a853-71930ae85c9b',
    firstName: 'Emil',
    lastName: 'Jørgensen',
    username: 'greenduck516',
    avatar: 'https://randomuser.me/api/portraits/men/19.jpg',
  },
  {
    uuid: '95f50c61-a9be-4e20-bed4-91666a979c8d',
    firstName: 'Leah',
    lastName: 'Hanson',
    username: 'goldenmouse196',
    avatar: 'https://randomuser.me/api/portraits/women/36.jpg',
  },
  {
    uuid: '18822d98-d0ef-4a2f-8b2f-4f72f70c4def',
    firstName: 'Anna',
    lastName: 'Mortensen',
    username: 'yellowfish998',
    avatar: 'https://randomuser.me/api/portraits/women/92.jpg',
  },
  {
    uuid: 'fcc31b63-2d3c-442d-91bc-588e46d83341',
    firstName: 'Sophie',
    lastName: 'Scott',
    username: 'silverfrog584',
    avatar: 'https://randomuser.me/api/portraits/women/28.jpg',
  },
  {
    uuid: 'ec15760b-ccd7-4335-a5ee-c6b3fcf72f55',
    firstName: 'Salma',
    lastName: 'Rivas',
    username: 'tinypeacock483',
    avatar: 'https://randomuser.me/api/portraits/women/95.jpg',
  },
  {
    uuid: '11b2c10f-ebe9-405f-91fc-0bda91f5db8e',
    firstName: 'Andreas',
    lastName: 'Christensen',
    username: 'yellowrabbit104',
    avatar: 'https://randomuser.me/api/portraits/men/67.jpg',
  },
  {
    uuid: 'c38ad0d9-0f47-458a-9d3e-d5d620ed118d',
    firstName: 'Rasmus',
    lastName: 'Jensen',
    username: 'bluewolf863',
    avatar: 'https://randomuser.me/api/portraits/men/43.jpg',
  },
  {
    uuid: 'a7986b13-eb97-4ce6-ac98-a0989e77c099',
    firstName: 'Mark',
    lastName: 'Johnston',
    username: 'purpleleopard675',
    avatar: 'https://randomuser.me/api/portraits/men/51.jpg',
  },
  {
    uuid: '2d9c4bd5-73db-4c51-b59d-8dad5a050b8b',
    firstName: 'Carla',
    lastName: 'Tejeda',
    username: 'ticklishleopard879',
    avatar: 'https://randomuser.me/api/portraits/women/34.jpg',
  },
  {
    uuid: '46b7a649-4fc6-45b7-a2ef-808500df6523',
    firstName: 'Đuro',
    lastName: 'Marinković',
    username: 'tinyzebra570',
    avatar: 'https://randomuser.me/api/portraits/men/33.jpg',
  },
  {
    uuid: 'e27e28b3-ee07-4d28-a445-3a018152afc7',
    firstName: 'Taara',
    lastName: 'Belligatti',
    username: 'goldenduck683',
    avatar: 'https://randomuser.me/api/portraits/women/53.jpg',
  },
  {
    uuid: '04fd7b6c-3a05-4812-8901-60af0de9ea74',
    firstName: 'Alan',
    lastName: 'Freeman',
    username: 'beautifulbird448',
    avatar: 'https://randomuser.me/api/portraits/men/98.jpg',
  },
  {
    uuid: '5feae105-13e0-45a3-a05b-4b7af322eb37',
    firstName: 'Esther',
    lastName: 'Wheeler',
    username: 'sadfish769',
    avatar: 'https://randomuser.me/api/portraits/women/93.jpg',
  },
  {
    uuid: '98557a02-6c17-46ce-9e6a-5b528b58d21f',
    firstName: 'فاطمه زهرا',
    lastName: 'كامياران',
    username: 'purplegoose744',
    avatar: 'https://randomuser.me/api/portraits/women/52.jpg',
  },
  {
    uuid: '46b5e975-64f7-4403-928d-d96c31df7435',
    firstName: 'Enzo',
    lastName: 'Guillot',
    username: 'orangesnake851',
    avatar: 'https://randomuser.me/api/portraits/men/77.jpg',
  },
  {
    uuid: '18e1753e-cae2-4074-b6e3-2fe32c7ebcbc',
    firstName: 'Arnout',
    lastName: 'Posthumus',
    username: 'tinyduck243',
    avatar: 'https://randomuser.me/api/portraits/men/20.jpg',
  },
  {
    uuid: 'd812be5c-db9b-4598-96ed-661a01d350b9',
    firstName: 'Velimir',
    lastName: 'Pejaković',
    username: 'whiteswan448',
    avatar: 'https://randomuser.me/api/portraits/men/66.jpg',
  },
  {
    uuid: 'b1472d09-1ce0-4e82-8766-a5f616fc3a97',
    firstName: 'Noelle',
    lastName: 'Brewer',
    username: 'greenostrich434',
    avatar: 'https://randomuser.me/api/portraits/women/9.jpg',
  },
  {
    uuid: '8419ed33-23d8-4bf8-b21c-44be16a08371',
    firstName: 'Johnny',
    lastName: 'Foster',
    username: 'bigpeacock334',
    avatar: 'https://randomuser.me/api/portraits/men/91.jpg',
  },
  {
    uuid: '00950978-1aaf-43a9-a4a0-b91be0f25dad',
    firstName: 'Amelia',
    lastName: 'Barbier',
    username: 'angrypanda662',
    avatar: 'https://randomuser.me/api/portraits/women/85.jpg',
  },
  {
    uuid: '213fa456-cfc8-45b3-9afc-d35494478733',
    firstName: 'Charlie',
    lastName: 'Bertrand',
    username: 'whiterabbit839',
    avatar: 'https://randomuser.me/api/portraits/men/28.jpg',
  },
  {
    uuid: '22d03393-4bfc-456c-afa4-490fb67399d0',
    firstName: 'Vist',
    lastName: 'Vikul',
    username: 'redelephant373',
    avatar: 'https://randomuser.me/api/portraits/men/98.jpg',
  },
  {
    uuid: 'ef67ce48-caee-4e50-b2cc-4dad3cbb87af',
    firstName: 'Soline',
    lastName: 'Simon',
    username: 'smallfish864',
    avatar: 'https://randomuser.me/api/portraits/women/21.jpg',
  },
  {
    uuid: 'cdf29b80-0d0b-4dfc-8721-1ec09b345802',
    firstName: 'Roope',
    lastName: 'Seppala',
    username: 'bluebird179',
    avatar: 'https://randomuser.me/api/portraits/men/3.jpg',
  },
  {
    uuid: 'bf338fb8-8b02-4bee-9be0-6cee1f1b02d8',
    firstName: 'مانی',
    lastName: 'یاسمی',
    username: 'tinybear480',
    avatar: 'https://randomuser.me/api/portraits/men/93.jpg',
  },
];
