import { BorderColor } from 'global/enums/colors';
import { AvatarSize } from 'global/enums/sizes';
import { IconAvatar } from 'global/lib/icons';
import { getIconSizeFromAvatarSize } from 'utils/getStyle';

function Avatar({ src, size }: { src?: string; size?: AvatarSize }) {
  return (
    <>
      {src ? (
        <div
          className={`${size} bg-cover bg-center bg-no-repeat rounded-full border ${BorderColor.PRIMARY}`}
          style={{
            backgroundImage: `url(${src})`,
          }}
        ></div>
      ) : (
        <div
          className={`${size} flex items-center justify-center rounded-full border ${BorderColor.PRIMARY}`}
        >
          <IconAvatar fontSize={getIconSizeFromAvatarSize(size)} />
        </div>
      )}
    </>
  );
}

export default Avatar;

export const AvatarSmall = ({ src }: { src?: string }) => {
  return <Avatar src={src} size={AvatarSize.SMALL} />;
};

export const AvatarMedium = ({ src }: { src?: string }) => {
  return <Avatar src={src} size={AvatarSize.MEDIUM} />;
};

export const AvatarLarge = ({ src }: { src?: string }) => {
  return <Avatar src={src} size={AvatarSize.LARGE} />;
};
