import axios from 'axios';
import { Message } from 'states/Message/Message';
import { SimpleUser } from 'states/User/User';
import {
  randomDate,
  randomInt,
} from 'utils/random';
import { sortMessagesByNewDate } from 'utils/sort';

import { followingsMessages } from './followingsMessages';

export const fetchRandomMessagesFromApi = async (): Promise<Message[]> => {
  const res = await axios.get('https://jsonplaceholder.typicode.com/comments');
  const messages = res.data.map((message: any) => {
    return {
      uuid: message.id,
      content: message.body,
      createdAt: randomDate(),
    } as Message;
  });
  return messages;
};

export const randomMessages = (): Message[] => {
  const messages: Message[] = [];
  fetchRandomMessagesFromApi()
    .then((res) => {
      messages.push(...res);
    })
    .catch((error) => {});

  return messages;
};

export const randomMessageFromList = (messages: Message[]) => {
  const message = messages[randomInt(messages.length)];
  const rand = randomInt(1, message.content.length);
  return {
    ...message,
    content: message.content.slice(0, rand),
  };
};

export const randomMessagesFromList = (
  from: SimpleUser,
  to: SimpleUser,
  skip: number,
  limit: number
) => {
  const mess: Message[] = [];
  const sortedMessages = sortMessagesByNewDate(followingsMessages);
  if (from && to) {
    const len = randomInt(50, 100);
    for (let i = skip; i < skip + limit; i++) {
      const randUser = randomInt();
      const randContent = randomInt(1, sortedMessages[i].content.length);
      if (randUser % 2 === 0) {
        mess.push({
          uuid: sortedMessages[i].uuid,
          content: sortedMessages[i].content.slice(0, randContent),
          from,
          to,
          createdAt: sortedMessages[i].createdAt,
        });
      } else {
        mess.push({
          uuid: sortedMessages[i].uuid,
          content: sortedMessages[i].content.slice(0, randContent),
          from: to,
          to: from,
          createdAt: sortedMessages[i].createdAt,
        });
      }
    }
  }
  return sortMessagesByNewDate(mess);
};
