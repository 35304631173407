import {
  useBgColorByTheme,
  useIconColorByBg,
} from 'global/hooks/useStyle';
import { IconPlay } from 'global/lib/icons';
import { Video } from 'states/MyMedias/MyMedias';

import CardMediaFullScreen from '../';
import { BadgeMediaBottomFullScreenGap } from '../../BadgeMedia';

function CardVideoFullScreen({ video }: { video: Video }) {
  const bgColor = useBgColorByTheme();
  const iconColor = useIconColorByBg(bgColor);
  return (
    <CardMediaFullScreen media={video}>
      <BadgeMediaBottomFullScreenGap>
        <>
          <IconPlay className={iconColor} fontSize="small" />
          <span>{video.nbViews}</span>
        </>
      </BadgeMediaBottomFullScreenGap>
    </CardMediaFullScreen>
  );
}

export default CardVideoFullScreen;
