export enum Lang {
  EN = 'en',
  FR = 'fr',
}

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface Settings {
  lang: Lang;
  theme: Theme;
}
