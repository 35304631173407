import { UserWithMessages } from 'states/User/User';

import { followingsMessages } from './followingsMessages';
import { followingsUsers } from './followingsUsers';
import { randomMessageFromList } from './messages';

export const followingsUserWithLastMessage: UserWithMessages[] = followingsUsers.map((user) => {
  return {
    ...user,
    lastMessage: randomMessageFromList(followingsMessages),
  } as UserWithMessages;
});
