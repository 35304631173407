import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { Theme } from 'states/Settings/Settings';
import { selectTheme } from 'states/Settings/settingSelectors';

function ThemeWrapper({ children }: { children: JSX.Element }) {
  const theme = useSelector(selectTheme);

  const themeStyle = useMemo(() => {
    switch (theme) {
      case Theme.LIGHT:
        return 'bg-white text-black';
      case Theme.DARK:
        return 'bg-black text-white';
      default:
        return 'bg-white text-black';
    }
  }, [theme]);

  return <div className={themeStyle}>{children}</div>;
}

export default ThemeWrapper;
