import { useSelector } from 'react-redux';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import { selectUser } from 'states/User/userSelectors';

export default function HeaderSecondary() {
  const user = useSelector(selectUser);
  const location = useLocation();

  return (
    <div className="sticky top-0 left-0 z-10 py-2 px-3 text-sm text-white">
      <div className="flex justify-center items-center">
        <Link to={`/login`} className="flex items-center text-center">
          <span className="font-semibold text-center">My Network</span>
        </Link>
      </div>
    </div>
  );
}
