import { IconPlay } from 'global/lib/icons';
import { Video } from 'states/MyMedias/MyMedias';

import CardMediaLarge from '../';
import { BadgeMediaBottomLargeGap } from '../../BadgeMedia';
import CardVideoHidden from '../../CardMediaHidden/CardVideoHidden';

function CardVideoLarge({ video }: { video: Video }) {
  return (
    <CardVideoHidden video={video}>
      <CardMediaLarge media={video}>
        <BadgeMediaBottomLargeGap>
          <>
            <IconPlay fontSize="small" />
            <span>{video.nbViews}</span>
          </>
        </BadgeMediaBottomLargeGap>
      </CardMediaLarge>
    </CardVideoHidden>
  );
}

export default CardVideoLarge;
