import { TextColor } from 'global/enums/colors';
import { numberFormatter } from 'utils/formatters';

interface Props {
  onClick?: () => void;
  badge?: number;
  badgeColor?: TextColor;
  children: any;
}

function TabMedia({ onClick, badge, badgeColor, children }: Props) {
  return (
    <div className="relative mx-auto" onClick={onClick}>
      {children}
      <div className={`absolute -top-1 -right-1 w-1 h-1 text-xs font-semibold ${badgeColor}`}>
        {badge ? numberFormatter.format(badge) : undefined}
      </div>
    </div>
  );
}

export default TabMedia;
