import { Media } from 'states/MyMedias/MyMedias';

import CardMedia from '../';
import { BadgeMediaBottomSmallGap } from '../BadgeMedia';

interface Props {
  media: Media;
  children: JSX.Element;
  handleClick?: () => void;
}

function CardMediaSmall({ media, children, handleClick }: Props) {
  return (
    <CardMedia src={media.link} handleClick={handleClick}>
      <BadgeMediaBottomSmallGap>{children}</BadgeMediaBottomSmallGap>
    </CardMedia>
  );
}

export default CardMediaSmall;
