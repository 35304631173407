import React, { useState } from 'react';

interface Props {
  hiddenComponent: JSX.Element;
  children: JSX.Element;
}

function CardMediaHidden({ hiddenComponent, children }: Props) {
  const [hidden, setHidden] = useState(true);
  const handleClick = () => {
    setHidden(!hidden);
  };
  return (
    <>
      <div onClick={handleClick}>{children}</div>
      <div className={`fixed top-0 left-0 z-50 ${hidden ? 'hidden' : ''}`} onClick={handleClick}>
        {hiddenComponent}
      </div>
    </>
  );
}

export default CardMediaHidden;
