import alertReducer from 'states/Alert/alertSlice';
import myMediasReducer from 'states/MyMedias/myMediasSlice';
import othersMediasReducer from 'states/OthersMedias/othersMediasSlice';
import peopleReducer from 'states/People/peopleSlice';
import settingsReducer from 'states/Settings/settingSlice';
import userReducer from 'states/User/userSlice';

import {
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';

const allReducers = combineReducers({
  alert: alertReducer,
  user: userReducer,
  settings: settingsReducer,
  myMedias: myMediasReducer,
  othersMedias: othersMediasReducer,
  people: peopleReducer,
});

const store = configureStore({
  reducer: allReducers,
});

export default store;
export type RootState = ReturnType<typeof allReducers>;
