export const randomDate = (start?: Date, end?: Date) => {
  const startDate = start || new Date(2012, 0, 1);
  const endDate = end || new Date();
  return JSON.stringify(
    new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()))
  );
};

export const randomInt = (min?: number, max?: number) => {
  const minVal = min ? min : 0;
  const maxVal = max ? max : 100;
  return Math.floor(Math.random() * (maxVal - minVal + 1)) + minVal;
};
