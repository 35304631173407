export enum GridCols {
  ONE = 'grid-cols-1',
  THREE = 'grid-cols-3',
  FOUR = 'grid-cols-4',
}

export enum MediaType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum MediaFormat {
  SQUARE = 'aspect-square bg-cover w-full',
  LARGE = 'aspect-square bg-contain w-full',
  FULLSCREEN = 'aspect-auto bg-contain h-screen w-screen',
}

export enum BadgePosition {
  TOP_SMALL_GAP = 'top-1 left-1',
  TOP_MEDIUM_GAP = 'top-1 left-1',
  TOP_LARGE_GAP = 'top-2 left-2',
  TOP_FULLSCREEN_GAP = 'top-4 left-4',
  BOTTOM_SMALL_GAP = 'bottom-1 left-1',
  BOTTOM_MEDIUM_GAP = 'bottom-1 left-1',
  BOTTOM_LARGE_GAP = 'bottom-2 left-2',
  BOTTOM_FULLSCREEN_GAP = 'bottom-4 left-4',
  LEFT = 'top-1 left-1 flex-col',
  RIGHT = 'top-1 right-1 flex-col',
}
