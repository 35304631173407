import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { UserWithMessages } from 'states/User/User';

import RowInboxSummary from './RowInboxSummary';

function TableInboxScroll({ fetchData }: { fetchData: () => Promise<UserWithMessages[]> }) {
  const limitedNumber = useMemo(() => 20, []);
  const dispatch = useDispatch();

  const [selectedPeople, setSelectedPeople] = useState<UserWithMessages[]>([]);

  useEffect(() => {
    fetchData()
      .then((res) => {
        setSelectedPeople((prev) => [...prev, ...res]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const fetchMoreData = useCallback(() => {
    fetchData()
      .then((res) => {
        setSelectedPeople((prev) => [...prev, ...res]);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <InfiniteScroll
      dataLength={selectedPeople.length}
      next={fetchMoreData}
      hasMore={true}
      loader={<h4>Loading...</h4>}
      className="flex flex-col space-y-2 justify-between"
    >
      {selectedPeople.map((person, index) => (
        <RowInboxSummary key={index} person={person} />
      ))}
    </InfiniteScroll>
  );
}

export default TableInboxScroll;
