import React from 'react';

import LayoutSecondary from 'layouts/LayoutSecondary';
import { useTranslation } from 'react-i18next';

import FormLogin from './FormLogin';

function PageLogin() {
  const { t } = useTranslation();

  return (
    <LayoutSecondary>
      <div className="bg-cover h-screen w-screen">
        <div className="container mx-auto h-full flex flex-1 justify-center items-center">
          <div className="w-full max-w-lg">
            <div className="leading-loose">
              <FormLogin />
            </div>
          </div>
        </div>
      </div>
    </LayoutSecondary>
  );
}

export default PageLogin;
