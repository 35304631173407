import { IconLove } from 'global/lib/icons';
import { Image } from 'states/MyMedias/MyMedias';

import CardMediaSmall from '../';
import CardImageHidden from '../../CardMediaHidden/CardImageHidden';

function CardImageSmall({ image }: { image: Image }) {
  return (
    <CardImageHidden image={image}>
      <CardMediaSmall media={image}>
        <>
          <IconLove fontSize="small" />
          <span>{image.nbLikes}</span>
        </>
      </CardMediaSmall>
    </CardImageHidden>
  );
}

export default CardImageSmall;
