import { useMemo } from 'react';

import { TextColor } from 'global/enums/colors';
import useLink from 'global/hooks/usePrivateLink';
import { IconFollowing } from 'global/lib/icons';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { paths } from 'routes/paths';
import { selectUser } from 'states/User/userSelectors';

import BarItem from '../';

function NbFollowing() {
  const user = useSelector(selectUser);
  const link = useLink(paths.followings.index);
  const location = useLocation();

  const textColor = useMemo(
    () => (location.pathname.endsWith(paths.followings.index) ? TextColor.PRIMARY : ''),
    [location]
  );

  return (
    <BarItem linkTo={link} badge={user.socialStatistics.nbFollowings} textColor={textColor}>
      <IconFollowing />
    </BarItem>
  );
}

export default NbFollowing;
