import { followingsMedias } from 'global/constants/data-test/followingsMedias';
import { myBookmarkedMedias } from 'global/constants/data-test/myLikedMedias';
import { myPhotos } from 'global/constants/data-test/myPhotos';
import { mySharedMedias } from 'global/constants/data-test/mySharedMedias';
import { myVideos } from 'global/constants/data-test/myVideos';
import { publicMedias } from 'global/constants/data-test/publicMedias';
import { Media } from 'states/MyMedias/MyMedias';
import {
  sortImagesByNbLikes,
  sortMediasByDate,
  sortVideosByNbViews,
} from 'utils/sort';

const PHOTO_BASE_URL = 'https://picsum.photos/200/300?image=';
export const getMyPhotosOrderByDate = async (skip: number, limit: number) => {
  const photos = await sortMediasByDate(myPhotos).slice(skip, skip + limit);
  return photos;
};

export const getMyPhotosOrderByNbLikes = async (skip: number, limit: number) => {
  const photos = await sortImagesByNbLikes(myPhotos).slice(skip, skip + limit);
  return photos;
};

export const getMyVideosOrderByDate = async (skip: number, limit: number) => {
  const videos = await sortMediasByDate(myVideos).slice(skip, skip + limit);
  return videos;
};

export const getMyVideosOrderByNbViews = async (skip: number, limit: number) => {
  const videos = await sortVideosByNbViews(myVideos).slice(skip, skip + limit);
  return videos;
};

export const getSharedMediasOrderByDate = async (skip: number, limit: number) => {
  const medias = await sortMediasByDate(mySharedMedias).slice(skip, skip + limit);
  return medias;
};

export const getBookmarkedMediasOrderByDate = async (skip: number, limit: number) => {
  const medias = await sortMediasByDate(myBookmarkedMedias).slice(skip, skip + limit);
  return medias;
};

export const getPublicMediasOrderByDate = async (skip: number, limit: number) => {
  const medias = await sortMediasByDate(publicMedias).slice(skip, skip + limit);
  return medias;
};

export const getFollowingsMediasOrderByDate = async (
  skip: number,
  limit: number
): Promise<Media[]> => {
  const medias = await sortMediasByDate(followingsMedias).slice(skip, skip + limit);
  return medias;
};
