import { useMemo } from 'react';

import {
  MediaFormat,
  MediaType,
} from 'global/enums';
import { CardSize } from 'global/enums/sizes';
import { useBgColorByTheme } from 'global/hooks/useStyle';

import CardImageLarge from './CardMediaLarge/CardImageLarge';
import CardVideoLarge from './CardMediaLarge/CardVideoLarge';
import CardImageMedium from './CardMediaMedium/CardImageMedium';
import CardVideoMedium from './CardMediaMedium/CardVideoMedium';
import CardImageSmall from './CardMediaSmall/CardImageSmall';
import CardVideoSmall from './CardMediaSmall/CardVideoSmall';

interface Props {
  src: string;
  form?: MediaFormat;
  children?: JSX.Element;
  handleClick?: () => void;
}

export default function CardMedia({ src, form, handleClick, children }: Props) {
  const mediaForm = useMemo(() => (form ? form : MediaFormat.SQUARE), [form]);
  const bgColor = useBgColorByTheme();

  return (
    <div
      className={`${mediaForm} ${bgColor} bg-center bg-no-repeat`}
      style={{
        backgroundImage: `url(${src})`,
      }}
      onClick={handleClick}
    >
      <div className="relative w-full h-full">{children}</div>
    </div>
  );
}

export const getCardImage = (cardSize: CardSize) => {
  switch (cardSize) {
    case CardSize.MEDIUM:
      return CardImageMedium;
    case CardSize.LARGE:
      return CardImageLarge;
    default:
      return CardImageSmall;
  }
};

export const getCardVideo = (cardSize: CardSize) => {
  switch (cardSize) {
    case CardSize.MEDIUM:
      return CardVideoMedium;
    case CardSize.LARGE:
      return CardVideoLarge;
    default:
      return CardVideoSmall;
  }
};

export const getCardMedia = ({ cardSize, type }: { cardSize: CardSize; type: MediaType }) => {
  switch (type) {
    case MediaType.VIDEO:
      return getCardVideo(cardSize);
    default:
      return getCardImage(cardSize);
  }
};
