import { AvatarSmall } from 'global/components/Avatar';
import { Message } from 'states/Message/Message';
import { formatDateTime } from 'utils/formatters';

function MessageRight({ message }: { message: Message }) {
  return (
    <div className="flex justify-end items-center">
      <div className="text-xs shrink text-end">{formatDateTime(new Date(message.createdAt))}</div>
      <div className="bg-gray-200 rounded-lg p-2 m-1 text-end">{message.content}</div>
      <div>
        <AvatarSmall src={message.from?.avatar} />
      </div>
    </div>
  );
}

export default MessageRight;
