import {
  useCallback,
  useMemo,
} from 'react';

import { TextColor } from 'global/enums/colors';
import useLink from 'global/hooks/usePrivateLink';
import { IconPhoto } from 'global/lib/icons';
import { IconPhotoSolidPrimary } from 'global/lib/IconsPrimary';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { paths } from 'routes/paths';
import { selectUser } from 'states/User/userSelectors';
import { isProfileTabPhotos } from 'utils/pathCheck';

import TabMedia from '../';

function TabPhoto() {
  const location = useLocation();
  const link = useLink(paths.profile.myPhotos);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tabIcon: any = useMemo(
    () => (isProfileTabPhotos(location.pathname) ? <IconPhotoSolidPrimary /> : <IconPhoto />),
    [location]
  );

  const badgeColor = useMemo(
    () => (isProfileTabPhotos(location.pathname) ? TextColor.PRIMARY : undefined),
    [location]
  );

  const onClick = useCallback(() => {
    navigate(link);
  }, [link]);

  return (
    <TabMedia onClick={onClick} badge={user.socialStatistics.nbPhotos} badgeColor={badgeColor}>
      {tabIcon}
    </TabMedia>
  );
}

export default TabPhoto;
