export const numberFormatter = Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
});

export const cutString = (str: string, length: number) => {
  if (str.length > length) {
    return str.slice(0, length) + '...';
  }
  return str;
};

export const cutStringForSmallBadge = (str: string) => {
  return cutString(str, 6);
};

export const formatDateTime = (date: Date) => {
  return date.toLocaleString().replace(' AM', 'am').replace(' PM', 'pm').replace(',', '');
};
