import {
  IconMenuDefault,
  IconPersonSearchDefault,
} from 'global/lib/IconsDefault';
import { IconPersonSearchSolidPrimary } from 'global/lib/IconsPrimary';
import { useSelector } from 'react-redux';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import { paths } from 'routes/paths';
import { selectUser } from 'states/User/userSelectors';
import { isPeoplePage } from 'utils/pathCheck';

export default function Header() {
  const user = useSelector(selectUser);
  const location = useLocation();

  return (
    <div className="sticky top-0 left-0 z-10 py-2 px-3 text-sm">
      <div className="flex justify-between items-center">
        <Link to={`/${user.username}${paths.people}`}>
          {isPeoplePage(location.pathname) ? (
            <IconPersonSearchSolidPrimary />
          ) : (
            <IconPersonSearchDefault />
          )}
        </Link>
        <Link to={`/${user.username}${paths.profile.index}`} className="flex items-center">
          <span className="font-semibold">{`${user.firstName} ${user.lastName}`}</span>
        </Link>
        <div className="flex">
          <Link to={`/${user.username}${paths.settings.index}`}>
            <IconMenuDefault />
          </Link>
        </div>
      </div>
    </div>
  );
}
