import { AvatarSmall } from 'global/components/Avatar';
import { MediaFormat } from 'global/enums';
import { Media } from 'states/MyMedias/MyMedias';

import CardMedia from '../';
import { BadgeMediaTopLargeGap } from '../BadgeMedia';

interface Props {
  media: Media;
  children: JSX.Element;
  handleClick?: () => void;
}

function CardMediaLarge({ media, children, handleClick }: Props) {
  return (
    <CardMedia src={media.link} form={MediaFormat.LARGE} handleClick={handleClick}>
      <>
        <BadgeMediaTopLargeGap>
          <>
            <AvatarSmall src={media.owner?.avatar} />
            <span className="bg-black py-1 px-2 rounded-md opacity-50 font-bold">{`${
              media.owner?.firstName ?? 'Unkown'
            } ${media.owner?.lastName ?? 'User'}`}</span>
          </>
        </BadgeMediaTopLargeGap>
        {children}
      </>
    </CardMedia>
  );
}

export default CardMediaLarge;
