import React, { useEffect } from 'react';

import {
  Controller,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Link,
  useNavigate,
} from 'react-router-dom';
import { paths } from 'routes/paths';
import { selectUser } from 'states/User/userSelectors';
import { setLogin } from 'states/User/userSlice';

export interface FormLoginData {
  username: string;
  password: string;
}

export const validUser = {
  username: 'chabong',
  password: 'Ch@bong2020',
};

function FormLogin() {
  const { t } = useTranslation();
  const { control, register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const onSubmit = (data: any) => {
    dispatch(setLogin(data as FormLoginData));
  };

  useEffect(() => {
    if (user.username === validUser.username && user.password === validUser.password) {
      navigate(`/${user.username}${paths.profile.index}`);
    }
  }, [user.username, user.password, navigate]);

  return (
    <form
      className="max-w-sm m-4 p-10 bg-white bg-opacity-25  rounded-lg shadow-xl"
      onSubmit={handleSubmit(onSubmit)}
    >
      <p className="text-white font-medium text-center text-lg font-bold">{t('common:login')}</p>
      <div className="">
        <label className="block text-sm text-white">{t('common:username')}</label>
        <Controller
          control={control}
          name="username"
          render={() => (
            <input
              className="w-full px-5 py-1 text-gray-700 bg-gray-300 rounded focus:outline-none focus:bg-white"
              type="text"
              placeholder="Enter your username"
              required
              {...register('username')}
            />
          )}
        />
      </div>
      <div className="mt-2">
        <label className="block  text-sm text-white">{t('common:password')}</label>
        <input
          className="w-full px-5 py-1 text-gray-700 bg-gray-300 rounded focus:outline-none focus:bg-white"
          type="password"
          placeholder="Enter your password"
          required
          {...register('password')}
        />
      </div>

      <div className="mt-4 items-center flex justify-between">
        <button
          className="px-4 py-1 text-white font-light tracking-wider bg-gray-900 hover:bg-gray-800 rounded"
          type="submit"
        >
          {t('common:enter')}
        </button>
        <Link
          to={'/forgot-password'}
          className="inline-block right-0 align-baseline font-bold text-sm text-500 text-white hover:text-red-400"
        >
          {t('common:forgotPassword')}
        </Link>
      </div>
      <div className="text-center">
        <a className="inline-block right-0 align-baseline font-semibold text-lg text-500 hover:text-red-400">
          {t('common:register')}
        </a>
      </div>
    </form>
  );
}

export default FormLogin;
