import {
  followingsUserWithLastMessage,
} from 'global/constants/data-test/followingsUserWithLastMessage';
import { randomMessagesFromList } from 'global/constants/data-test/messages';
import { SimpleUser } from 'states/User/User';

export const getFollowingsUserWithLastMessage = async (skip: number, limit: number) => {
  const messages = await followingsUserWithLastMessage.slice(skip, skip + limit);
  return messages;
  //   return axios.get(`/user/message?skip=${skip}&limit=${limit}`);
};

export const getMessagesWithUser = async (
  fromUser: SimpleUser,
  toUser: SimpleUser,
  skip: number,
  limit: number
) => {
  const messages = await randomMessagesFromList(fromUser, toUser, skip, limit);
  return messages;
};
