import { followersUsers } from 'global/constants/data-test/followersUsers';
import { followingsUsers } from 'global/constants/data-test/followingsUsers';
import { publicUsers } from 'global/constants/data-test/publicUsers';

export const getPublicUsers = async (skip: number, limit: number) => {
  const users = await publicUsers.slice(skip, skip + limit);
  return users;
};

export const getFollowersUsers = async (skip: number, limit: number) => {
  const users = await followersUsers.slice(skip, skip + limit);
  return users;
};

export const getFollowingsUsers = async (skip: number, limit: number) => {
  const users = await followingsUsers.slice(skip, skip + limit);
  return users;
};
