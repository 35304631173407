import { Media } from 'states/MyMedias/MyMedias';
import { SimpleUser } from 'states/User/User';

export const myBookmarkedMedias: Media[] = [
  {
    link: 'https://media.tenor.com/dwoXDFGokUUAAAAC/flying-random.gif',
    nbViews: 412,
    owner: {
      uuid: '90aa46b6-d854-4c32-aab0-08ab2896e5e0',
      firstName: 'Kevin',
      lastName: 'Arnold',
      username: 'sadbear110',
      avatar: 'https://randomuser.me/api/portraits/men/72.jpg',
    } as SimpleUser,
    dateCreated: '2017-05-22T22:08:08.886Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=603',
    nbLikes: 952,
    owner: {
      uuid: 'fe8f677d-0ec4-460b-9d3e-8181a6962f13',
      firstName: 'Yuhima',
      lastName: 'Harchenko',
      username: 'tinyleopard194',
      avatar: 'https://randomuser.me/api/portraits/women/62.jpg',
    } as SimpleUser,
    dateCreated: '2012-12-24T20:12:31.611Z',
  },
  {
    link: 'https://media.tenor.com/Ibx5dIwIolUAAAAC/your-mom-kinda-cringe.gif',
    nbViews: 365,
    owner: {
      uuid: '0b4b24f6-a815-45b1-8f7b-776add108cbb',
      firstName: 'Alfred',
      lastName: 'Riemer',
      username: 'heavypanda537',
      avatar: 'https://randomuser.me/api/portraits/men/62.jpg',
    } as SimpleUser,
    dateCreated: '2020-09-21T05:25:33.319Z',
  },
  {
    link: 'https://media.tenor.com/y_Aumxs5ze4AAAAC/random-but.gif',
    nbViews: 795,
    owner: {
      uuid: 'ceed70d1-1185-472b-a88f-18c31f03faa3',
      firstName: 'Norma',
      lastName: 'Ulloa',
      username: 'yellowcat574',
      avatar: 'https://randomuser.me/api/portraits/women/11.jpg',
    } as SimpleUser,
    dateCreated: '2020-08-05T04:15:51.812Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=635',
    nbLikes: 752,
    owner: {
      uuid: '663e8169-0c18-4b9b-875f-285a89917a4b',
      firstName: 'Julia',
      lastName: 'Kumpula',
      username: 'yellowdog580',
      avatar: 'https://randomuser.me/api/portraits/women/49.jpg',
    } as SimpleUser,
    dateCreated: '2018-06-11T13:40:52.632Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=92',
    nbLikes: 190,
    owner: {
      uuid: '1bfbfd76-8243-4542-a14e-ec324f5d8884',
      firstName: 'Annabelle',
      lastName: 'Novak',
      username: 'tinytiger807',
      avatar: 'https://randomuser.me/api/portraits/women/28.jpg',
    } as SimpleUser,
    dateCreated: '2016-03-12T15:42:29.151Z',
  },
  {
    link: 'https://media.tenor.com/_5qd8_05sDUAAAAC/pinkant-addison.gif',
    nbViews: 395,
    owner: {
      uuid: 'a03fb62a-cbcf-460f-a33a-07699dfc0ea5',
      firstName: 'Laurie',
      lastName: 'Abraham',
      username: 'purplebird514',
      avatar: 'https://randomuser.me/api/portraits/women/58.jpg',
    } as SimpleUser,
    dateCreated: '2015-02-25T09:25:01.633Z',
  },
  {
    link: 'https://media.tenor.com/3yFQlMeQrdMAAAAC/spongebob-meme.gif',
    nbViews: 504,
    owner: {
      uuid: 'd0d5cba5-82d9-4180-bb76-4fd61f2229b9',
      firstName: 'Sandra',
      lastName: 'Alvarez',
      username: 'happyfish964',
      avatar: 'https://randomuser.me/api/portraits/women/8.jpg',
    } as SimpleUser,
    dateCreated: '2014-08-10T16:17:05.375Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=220',
    nbLikes: 22,
    owner: {
      uuid: '68a5e1ab-4767-4d53-b24c-75a08f5bbb99',
      firstName: 'Jamil',
      lastName: 'Oldenburg',
      username: 'crazypeacock495',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
    } as SimpleUser,
    dateCreated: '2019-01-14T01:35:19.373Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=610',
    nbLikes: 823,
    owner: {
      uuid: 'c0fbe46b-85f3-47eb-a92d-5415461f3421',
      firstName: 'Oswald',
      lastName: 'Berger',
      username: 'blackswan422',
      avatar: 'https://randomuser.me/api/portraits/men/33.jpg',
    } as SimpleUser,
    dateCreated: '2012-01-10T08:30:52.332Z',
  },
  {
    link: 'https://media.tenor.com/y4QbP8-Oo6gAAAAC/laughing-green.gif',
    nbViews: 89,
    owner: {
      uuid: '728d27fc-7e38-46e5-bfac-4ff739a19626',
      firstName: 'Libid',
      lastName: 'Vitchenko',
      username: 'smallfish978',
      avatar: 'https://randomuser.me/api/portraits/women/20.jpg',
    } as SimpleUser,
    dateCreated: '2015-05-24T02:54:43.903Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=848',
    nbLikes: 807,
    owner: {
      uuid: 'c07d4fcc-8843-4711-87e3-6daa8c85d66d',
      firstName: 'Svyatogor',
      lastName: 'Bandurka',
      username: 'tinyduck477',
      avatar: 'https://randomuser.me/api/portraits/men/64.jpg',
    } as SimpleUser,
    dateCreated: '2020-03-29T09:21:10.016Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=175',
    nbLikes: 921,
    owner: {
      uuid: '57e0cb55-0bd1-4a7c-b735-a86fcc0bc078',
      firstName: 'Eugenia',
      lastName: 'López',
      username: 'greenostrich591',
      avatar: 'https://randomuser.me/api/portraits/women/74.jpg',
    } as SimpleUser,
    dateCreated: '2017-09-13T18:44:46.284Z',
  },
  {
    link: 'https://media.tenor.com/s0bXRtpXyLcAAAAC/super-random-johnny-weiss.gif',
    nbViews: 709,
    owner: {
      uuid: '8aa9cda5-6abb-42db-a1d8-f4c488f9b4da',
      firstName: 'Kate',
      lastName: 'Taylor',
      username: 'orangezebra350',
      avatar: 'https://randomuser.me/api/portraits/women/50.jpg',
    } as SimpleUser,
    dateCreated: '2020-04-16T18:12:51.804Z',
  },
  {
    link: 'https://media.tenor.com/qj3vwUKiCvYAAAAC/random-funny.gif',
    nbViews: 196,
    owner: {
      uuid: '95a7fa90-32a9-4394-8194-11e029671c6b',
      firstName: 'Léonard',
      lastName: 'Gautier',
      username: 'organicelephant186',
      avatar: 'https://randomuser.me/api/portraits/men/1.jpg',
    } as SimpleUser,
    dateCreated: '2014-10-12T17:51:38.534Z',
  },
  {
    link: 'https://media.tenor.com/RtJifRTjOHEAAAAC/dancing-random.gif',
    nbViews: 842,
    owner: {
      uuid: '0123e8ad-28c2-4e78-9457-4bcab3364c98',
      firstName: 'Alex',
      lastName: 'Cooper',
      username: 'goldenkoala335',
      avatar: 'https://randomuser.me/api/portraits/men/68.jpg',
    } as SimpleUser,
    dateCreated: '2013-01-08T13:05:04.913Z',
  },
  {
    link: 'https://media.tenor.com/H_Htc8iPURMAAAAC/aot.gif',
    nbViews: 783,
    owner: {
      uuid: '62789b46-f7fb-40e7-b3ca-ba4b5b05ccb4',
      firstName: 'Lidija',
      lastName: 'Đokić',
      username: 'purplepeacock697',
      avatar: 'https://randomuser.me/api/portraits/women/32.jpg',
    } as SimpleUser,
    dateCreated: '2016-06-10T11:32:14.830Z',
  },
  {
    link: 'https://media.tenor.com/sRx6btX50RcAAAAC/cow-utter.gif',
    nbViews: 492,
    owner: {
      uuid: '4df59265-06d3-4bf2-ab6a-00868e637e76',
      firstName: 'Filippa',
      lastName: 'Poulsen',
      username: 'purplebird110',
      avatar: 'https://randomuser.me/api/portraits/women/54.jpg',
    } as SimpleUser,
    dateCreated: '2015-09-03T20:45:01.762Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=525',
    nbLikes: 114,
    owner: {
      uuid: 'a0dc9976-5f78-4076-941e-2e0be74e508e',
      firstName: 'Maria',
      lastName: 'Barnett',
      username: 'tinycat940',
      avatar: 'https://randomuser.me/api/portraits/women/88.jpg',
    } as SimpleUser,
    dateCreated: '2015-02-03T08:42:40.779Z',
  },
  {
    link: 'https://media.tenor.com/fcIdo2cPHR4AAAAC/random-pvz-ts.gif',
    nbViews: 531,
    owner: {
      uuid: '0f0c776a-0fea-4769-b808-e24921b39232',
      firstName: 'Arthur',
      lastName: 'Watson',
      username: 'angryleopard919',
      avatar: 'https://randomuser.me/api/portraits/men/62.jpg',
    } as SimpleUser,
    dateCreated: '2017-07-19T12:00:48.721Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=300',
    nbLikes: 381,
    owner: {
      uuid: 'c6f7d6ef-9712-482a-9785-71b4328b5d2c',
      firstName: 'Oliver',
      lastName: 'Lee',
      username: 'orangefrog549',
      avatar: 'https://randomuser.me/api/portraits/men/28.jpg',
    } as SimpleUser,
    dateCreated: '2021-04-13T17:39:18.186Z',
  },
  {
    link: 'https://media.tenor.com/p0HpVhLpXrIAAAAC/how-random-is-that-whats-happening.gif',
    nbViews: 77,
    owner: {
      uuid: 'ac959415-34c9-4c28-9d48-844912dde583',
      firstName: 'Mustafa',
      lastName: 'Tokgöz',
      username: 'crazyleopard580',
      avatar: 'https://randomuser.me/api/portraits/men/59.jpg',
    } as SimpleUser,
    dateCreated: '2012-09-04T18:06:06.380Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=223',
    nbLikes: 259,
    owner: {
      uuid: 'f64e71da-1ce4-4c8a-8334-922b3fc5bc90',
      firstName: 'پوریا',
      lastName: 'صدر',
      username: 'brownfish612',
      avatar: 'https://randomuser.me/api/portraits/men/74.jpg',
    } as SimpleUser,
    dateCreated: '2016-12-30T08:52:01.928Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=971',
    nbLikes: 750,
    owner: {
      uuid: 'e4e98784-9b5c-4c75-a763-9249e4bbe508',
      firstName: 'Sylvia',
      lastName: 'Flores',
      username: 'bluemouse230',
      avatar: 'https://randomuser.me/api/portraits/women/87.jpg',
    } as SimpleUser,
    dateCreated: '2016-09-13T09:11:28.757Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=241',
    nbLikes: 649,
    owner: {
      uuid: '2b3454c8-21dd-480d-a2dd-3c88009d4088',
      firstName: 'Barış',
      lastName: 'Sadıklar',
      username: 'ticklishswan295',
      avatar: 'https://randomuser.me/api/portraits/men/55.jpg',
    } as SimpleUser,
    dateCreated: '2019-08-23T17:05:08.047Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=421',
    nbLikes: 160,
    owner: {
      uuid: '3635dd5e-0c36-4190-ade9-b86232c055a2',
      firstName: 'Deniz',
      lastName: 'Gümüşpala',
      username: 'tinymeercat773',
      avatar: 'https://randomuser.me/api/portraits/men/15.jpg',
    } as SimpleUser,
    dateCreated: '2017-07-25T04:40:38.268Z',
  },
  {
    link: 'https://media.tenor.com/kNj3X8Iv1-EAAAAC/random-random-du-chateau.gif',
    nbViews: 222,
    owner: {
      uuid: 'db94aba6-89d8-4080-b354-68d770131bdd',
      firstName: 'Rina',
      lastName: 'Muller',
      username: 'crazygorilla583',
      avatar: 'https://randomuser.me/api/portraits/women/46.jpg',
    } as SimpleUser,
    dateCreated: '2017-08-18T21:51:20.728Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=634',
    nbLikes: 360,
    owner: {
      uuid: '5e5613b4-d7be-498c-b979-e3a3c9687e2c',
      firstName: 'Emilia',
      lastName: 'Trujillo',
      username: 'organictiger277',
      avatar: 'https://randomuser.me/api/portraits/women/74.jpg',
    } as SimpleUser,
    dateCreated: '2019-05-10T14:38:24.341Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=238',
    nbLikes: 318,
    owner: {
      uuid: '457760bf-9693-4762-9e07-4bb012e44b2b',
      firstName: 'تینا',
      lastName: 'سلطانی نژاد',
      username: 'heavyelephant759',
      avatar: 'https://randomuser.me/api/portraits/women/10.jpg',
    } as SimpleUser,
    dateCreated: '2016-01-11T00:40:21.890Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=115',
    nbLikes: 679,
    owner: {
      uuid: 'c52259da-93a8-468d-b0f6-f4f3c1434773',
      firstName: 'نیایش',
      lastName: 'سلطانی نژاد',
      username: 'happyzebra365',
      avatar: 'https://randomuser.me/api/portraits/women/76.jpg',
    } as SimpleUser,
    dateCreated: '2013-09-18T14:42:43.576Z',
  },
  {
    link: 'https://media.tenor.com/13t9cpC8Ou0AAAAC/random.gif',
    nbViews: 608,
    owner: {
      uuid: '18dbcb4f-1317-4e53-bdd9-c08530981d91',
      firstName: 'Annelise',
      lastName: 'Lefebvre',
      username: 'organicrabbit126',
      avatar: 'https://randomuser.me/api/portraits/women/21.jpg',
    } as SimpleUser,
    dateCreated: '2013-04-03T22:10:18.545Z',
  },
  {
    link: 'https://media.tenor.com/AC62zg5nPH4AAAAC/random.gif',
    nbViews: 353,
    owner: {
      uuid: 'a8815889-fcd2-48f7-81d9-41062433b4af',
      firstName: 'Pava',
      lastName: 'Malyarchuk',
      username: 'organicelephant158',
      avatar: 'https://randomuser.me/api/portraits/women/17.jpg',
    } as SimpleUser,
    dateCreated: '2015-03-02T21:48:06.305Z',
  },
  {
    link: 'https://media.tenor.com/fFntTHJYFPMAAAAC/random.gif',
    nbViews: 380,
    owner: {
      uuid: 'b295f1ca-8920-43d0-9ed8-dd4032ce8d21',
      firstName: 'Ruslana',
      lastName: 'Glavaš',
      username: 'orangesnake883',
      avatar: 'https://randomuser.me/api/portraits/women/19.jpg',
    } as SimpleUser,
    dateCreated: '2013-06-03T23:13:34.833Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=928',
    nbLikes: 743,
    owner: {
      uuid: '6563de28-d952-4234-91eb-5dcaef269513',
      firstName: 'Mehar',
      lastName: 'Holla',
      username: 'redgoose875',
      avatar: 'https://randomuser.me/api/portraits/women/89.jpg',
    } as SimpleUser,
    dateCreated: '2021-04-18T09:15:06.012Z',
  },
  {
    link: 'https://media.tenor.com/lt0biOTLTUoAAAAC/random-cats.gif',
    nbViews: 213,
    owner: {
      uuid: 'ed9c9711-ba08-4b37-a693-a18dc63c232a',
      firstName: 'Traude',
      lastName: 'Schüle',
      username: 'blueostrich191',
      avatar: 'https://randomuser.me/api/portraits/women/73.jpg',
    } as SimpleUser,
    dateCreated: '2016-05-05T23:32:31.518Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=608',
    nbLikes: 519,
    owner: {
      uuid: '905e7474-1231-4fdb-9bef-4a6226c43c68',
      firstName: 'Kenneth',
      lastName: 'Abels',
      username: 'tinyzebra346',
      avatar: 'https://randomuser.me/api/portraits/men/14.jpg',
    } as SimpleUser,
    dateCreated: '2020-02-03T20:12:54.505Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=807',
    nbLikes: 154,
    owner: {
      uuid: '7a1ea5fa-9201-4c6a-bcb9-12eb15257c94',
      firstName: 'Jeff',
      lastName: 'Patterson',
      username: 'silvermeercat165',
      avatar: 'https://randomuser.me/api/portraits/men/68.jpg',
    } as SimpleUser,
    dateCreated: '2021-02-21T21:03:59.461Z',
  },
  {
    link: 'https://media.tenor.com/0C3wYUtAb78AAAAC/david-kubrt-kubrt.gif',
    nbViews: 932,
    owner: {
      uuid: '4f5126f3-1a63-4593-8adc-47d402d7bf02',
      firstName: 'Emma',
      lastName: 'Sørensen',
      username: 'silverelephant515',
      avatar: 'https://randomuser.me/api/portraits/women/5.jpg',
    } as SimpleUser,
    dateCreated: '2013-09-21T18:37:44.947Z',
  },
  {
    link: 'https://media.tenor.com/fuGF9MgQ4uoAAAAC/bored-random.gif',
    nbViews: 915,
    owner: {
      uuid: '58540d74-8870-4cc2-aacd-669aa47cad97',
      firstName: 'Falan',
      lastName: 'Mathew',
      username: 'silversnake446',
      avatar: 'https://randomuser.me/api/portraits/men/66.jpg',
    } as SimpleUser,
    dateCreated: '2012-02-08T19:19:46.519Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=54',
    nbLikes: 208,
    owner: {
      uuid: '432e26cb-9011-4631-8e98-f126d3bc5b03',
      firstName: 'Dustin',
      lastName: 'Evans',
      username: 'organiclion948',
      avatar: 'https://randomuser.me/api/portraits/men/43.jpg',
    } as SimpleUser,
    dateCreated: '2016-03-04T12:29:43.718Z',
  },
  {
    link: 'https://media.tenor.com/k3_7ODBzTE4AAAAC/hi-jim-carrey.gif',
    nbViews: 416,
    owner: {
      uuid: '7d18d07a-5fc8-46b3-a6bb-919f06d0b585',
      firstName: 'Kayla',
      lastName: 'Stubberud',
      username: 'sadmeercat175',
      avatar: 'https://randomuser.me/api/portraits/women/0.jpg',
    } as SimpleUser,
    dateCreated: '2015-03-29T04:29:05.921Z',
  },
  {
    link: 'https://media.tenor.com/zqi2DFUitoYAAAAC/cat-indiana.gif',
    nbViews: 193,
    owner: {
      uuid: 'd38047b6-c09b-4874-a64a-26db0a667317',
      firstName: 'Sulani',
      lastName: 'da Costa',
      username: 'purpledog475',
      avatar: 'https://randomuser.me/api/portraits/women/4.jpg',
    } as SimpleUser,
    dateCreated: '2018-08-16T08:26:51.002Z',
  },
  {
    link: 'https://media.tenor.com/OIp_NbRUzlgAAAAC/mr-random-random.gif',
    nbViews: 707,
    owner: {
      uuid: 'd99e7627-cfbe-4d99-bda0-a763958f79ea',
      firstName: 'Sumana',
      lastName: 'Padmanabha',
      username: 'angrymouse531',
      avatar: 'https://randomuser.me/api/portraits/women/55.jpg',
    } as SimpleUser,
    dateCreated: '2015-09-28T12:13:01.899Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=530',
    nbLikes: 431,
    owner: {
      uuid: '62b92d39-bd13-483d-8dcd-5560b21de2d3',
      firstName: 'Jordan',
      lastName: 'Charles',
      username: 'smallsnake426',
      avatar: 'https://randomuser.me/api/portraits/men/6.jpg',
    } as SimpleUser,
    dateCreated: '2020-11-29T22:04:19.995Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=247',
    nbLikes: 604,
    owner: {
      uuid: 'eb027d3a-39fe-4eb1-8b81-9670b317d439',
      firstName: 'Sofia',
      lastName: 'Pedersen',
      username: 'bigmeercat455',
      avatar: 'https://randomuser.me/api/portraits/women/71.jpg',
    } as SimpleUser,
    dateCreated: '2021-06-30T05:39:27.287Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=772',
    nbLikes: 91,
    owner: {
      uuid: 'cf95a896-0d2f-4698-9846-092fc5a580c5',
      firstName: 'Riley',
      lastName: 'Washington',
      username: 'crazygoose146',
      avatar: 'https://randomuser.me/api/portraits/men/97.jpg',
    } as SimpleUser,
    dateCreated: '2013-12-12T14:14:48.510Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=250',
    nbLikes: 991,
    owner: {
      uuid: '1fae6659-3198-4d7e-8870-1b08aa086845',
      firstName: 'Axel',
      lastName: 'Roux',
      username: 'lazybird170',
      avatar: 'https://randomuser.me/api/portraits/men/51.jpg',
    } as SimpleUser,
    dateCreated: '2019-10-10T01:08:54.364Z',
  },
  {
    link: 'https://media.tenor.com/7Yb2DzUc9-8AAAAC/random-weird.gif',
    nbViews: 250,
    owner: {
      uuid: '7184ccb0-0388-4f76-8ec2-b35dc1f8876c',
      firstName: 'Jayden',
      lastName: 'Anderson',
      username: 'silvercat740',
      avatar: 'https://randomuser.me/api/portraits/men/85.jpg',
    } as SimpleUser,
    dateCreated: '2017-03-30T16:13:28.459Z',
  },
  {
    link: 'https://picsum.photos/200/300?random=483',
    nbLikes: 361,
    owner: {
      uuid: '85e4532a-a690-4910-b5a0-1ea6ff551d5c',
      firstName: 'Louis',
      lastName: 'Gauthier',
      username: 'redbear182',
      avatar: 'https://randomuser.me/api/portraits/men/40.jpg',
    } as SimpleUser,
    dateCreated: '2017-06-01T19:09:20.731Z',
  },
  {
    link: 'https://media.tenor.com/qVrBWtgm-M8AAAAC/spiritus-cockroach.gif',
    nbViews: 267,
    owner: {
      uuid: '1dc8b985-2140-40ce-a869-605b322d74ec',
      firstName: 'Axel',
      lastName: 'Robert',
      username: 'greenmeercat479',
      avatar: 'https://randomuser.me/api/portraits/men/70.jpg',
    } as SimpleUser,
    dateCreated: '2022-09-26T18:57:47.957Z',
  },
];
